import withStyles from '@material-ui/core/styles/withStyles';
import Fab from '@material-ui/core/Fab';
import DeleteIcon from '@material-ui/icons/Delete';
import * as React from 'react';
import { Tooltip } from '@material-ui/core';

interface IProps {
    onClick: () => void;
    label: string | undefined;
}

export default function DeleteButton(props: IProps) {
    const DeleteButton = withStyles({
        root: {
            background: 'red',
            color: 'white',
            '&:hover': {
                backgroundColor: 'darkRed'
            }
        },
        label: {
            textTransform: 'capitalize'
        }
    })(Fab);

    const button = () => {
        return (
            <DeleteButton
                aria-label='delete'
                onClick={props.onClick}
                size={'small'}
                variant={props.label ? 'extended' : 'circular'}>
                <DeleteIcon />
                {props.label}
            </DeleteButton>
        );
    };

    if (props.label) return button();
    else
        return (
            <Tooltip title={'Delete'} arrow>
                {button()}
            </Tooltip>
        );
}
