import * as React from 'react';
import { useEffect } from 'react';
import {
    Container,
    Dialog,
    DialogActions,
    DialogContentText,
    DialogTitle,
    Modal,
    Paper,
    Theme,
    Tooltip,
    createStyles
} from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import { HttpHelpers } from './HttpHelpers';
import { constants } from './constants';
import { useHistory, useParams } from 'react-router-dom';
import Fab from '@material-ui/core/Fab';
import EditIcon from '@material-ui/icons/Edit';
import BarChartIcon from '@material-ui/icons/BarChart';
import Box from '@material-ui/core/Box';
import { currencyFormatter } from './helpers/Helpers';
import Button from '@material-ui/core/Button';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import SlideshowIcon from '@material-ui/icons/Slideshow';
import { Markup } from 'interweave';
import DeleteButton from './DeleteButton';
import { IHaveJobIdParam } from './JobCreateEdit';
import { RoadshowVideoPlayer } from './Roadshow/RoadshowVideoPlayer';
import RoadshowSlidePlayer from './Roadshow/RoadshowSlidePlayer';
import * as H from 'history';
import {
    DocUploadControlFileEntry,
    DocUploadControlFileEntryType
} from './DocUploadCtrl';
import OrganizationListPaper from './OrganizationListPaper';

export type RoadshowType = 'video' | 'pdf';

export interface RoadshowTimestampEntry {
    title: string;
    slideNumber: number;
    timestampSeconds: number;
    timestampString: string;
}

export interface RoadshowMeta {
    fileName: string;
    roadshowType: RoadshowType;
    timestamps: Array<RoadshowTimestampEntry>;
    roadshowDisclaimerOverride?: string;
    videoFileName?: string;
}

export interface Job {
    key: string;
    jobNumber: string;
    saleDate: string;
    isPrivate: boolean;
    isFinal: boolean;
    isCompetitive: boolean;
    type: string;
    state: string;
    amount: string;
    issuer: string;
    title: string;
    description: string;
    leadManagers: string[];
    coManagers: string[];
    municipalAdvisors: string[];
    counsels: string[];
    files: DocUploadControlFileEntry[];
    postedDate: string; //TODO: use date type?
    jobOwnerEmails: string[];
    bondLinkUrl: string;
    disclaimerOverride: string;
    roadshowMetas: RoadshowMeta[];
    associatedFiles: {
        entries: Array<{category: string, fileName: string, displayName: string, show: boolean, order: number}>
    }
}

interface IJobViewProps {
    accessToken: string;
    isAdmin: boolean;
    refreshTokensCallback: (history: H.History<H.LocationState>) => void;
    signalAdajeWorking: (boolean, string) => void;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1
        },
        paper: {
            padding: theme.spacing(2),
            textAlign: 'left',
            color: theme.palette.text.secondary
        },
        header: {
            color: theme.palette.primary.main,
            paddingBottom: '20px'
        },
        fab: {
            '& > *': {
                margin: theme.spacing(1)
            }
        },
        button: {
            margin: theme.spacing(1)
        },
        topMargin: {
            marginTop: theme.spacing(3)
        },
        logo: {
            height: 'auto',
            maxWidth: '250px',
            maxHeight: '150px'
        },
        jobType: {
            paddingBottom: '20px',
            fontSize: '26px'
        },
        amount: {
            paddingBottom: '10px',
            fontWeight: 500
        },
        issuer: {
            fontWeight: 500
        }
    })
);

export interface Org {
    name: string;
    url: string;
    logo: string;
}

export default function JobView(props: IJobViewProps) {
    const classes = useStyles();

    let history = useHistory();

    let { jobId } = useParams<IHaveJobIdParam>();

    const refreshTokens = () => {
        props.refreshTokensCallback(history);
    };

    const [job, setJob] = React.useState<Job>(null);
    const [isOwner, setIsOwner] = React.useState(false);
    const [isDeleteConfirmationOpen, setIsDeleteConfirmationOpen] =
        React.useState(false);

    //State related to disclaimer dialog and subsequent file download
    const [disclaimerOpen, setDisclaimerOpen] = React.useState(false);
    const [selectedFileName, setSelectedFileName] = React.useState(null);

    //Roadshow view state
    const [roadshowDisclaimerOpen, setRoadshowDisclaimerOpen] =
        React.useState(false);
    const [roadshowDisclaimerAccepted, setRoadshowDisclaimerAccepted] =
        React.useState(false);
    const [roadshowDisclaimerBlockingIdx, setRoadshowDisclaimerBlockingIdx] = React.useState<number | undefined>()
    const [selectedRoadshowIdx, setSelectedRoadshowIdx] = React.useState<number | undefined>();

    const [orgs, setOrgs] = React.useState([]);

    const deleteJob = () => {
        props.signalAdajeWorking(true, 'Deleting job...');
        HttpHelpers.deleteJson(
            constants.urlPrefix + '/job/' + jobId,
            true,
            props.accessToken,
            refreshTokens
        )
            .then(result => {
                if (result.status === 200) {
                } else if (result.status === 400) {
                    console.log('fail 400');
                }
            })
            .catch(function (error) {
                console.log('Request failed', error);
            })
            .finally(() => {
                props.signalAdajeWorking(false, '');
                setIsDeleteConfirmationOpen(false);
                history.push('/');
            });
    };

    useEffect(() => {
        HttpHelpers.getJson(
            constants.urlPrefix + '/job/events/' + jobId,
            true,
            props.accessToken,
            refreshTokens
        )
            .then(result => {
                if (result.status === 200) {
                    setIsOwner(true);
                }
            })
            .catch(function (error) {
                console.log('Request failed', error);
            });

        HttpHelpers.postJsonParseJson(
            constants.urlPrefix + '/job/details',
            { jobId: jobId },
            props.accessToken,
            refreshTokens
        )
            .then(result => {
                if (result.status === 200) {
                    if (result.body.jobOption === undefined) {
                        alert('Job not found!'); //TODO replace with warning modal with redirect to "/"
                        history.push('/');
                    } else {
                        const jobInternal = result.body.jobOption;
                        const job: Job = {
                            key: jobInternal._id,
                            type: constants.jobTypes.find(e => {
                                return e.name === jobInternal.offeringType;
                            }).label,
                            state: jobInternal.state,
                            amount: jobInternal.par,
                            title: jobInternal.title,
                            issuer: jobInternal.issuer,
                            description: jobInternal.description,
                            leadManagers: jobInternal.leadManagerNames
                                ? jobInternal.leadManagerNames
                                : [],
                            coManagers: jobInternal.coManagerNames
                                ? jobInternal.coManagerNames
                                : [],
                            municipalAdvisors: jobInternal.municipalAdvisorNames
                                ? jobInternal.municipalAdvisorNames
                                : [],
                            counsels: jobInternal.counselNames
                                ? jobInternal.counselNames
                                : [],
                            postedDate: jobInternal.postedDate,
                            files: jobInternal.associatedFiles.entries,
                            jobOwnerEmails: jobInternal.jobOwnerEmails,
                            isFinal: jobInternal.isFinal,
                            isCompetitive: jobInternal.isCompetitive,
                            isPrivate: jobInternal.isPrivate,
                            saleDate: jobInternal.saleDate,
                            jobNumber: jobInternal.jobNumber,
                            bondLinkUrl: jobInternal.bondLinkUrl,
                            disclaimerOverride: jobInternal.disclaimerOverride,
                            roadshowMetas: jobInternal.roadshowMetas,
                            associatedFiles: jobInternal.associatedFiles
                        };
                        setJob(job);
                    }
                } else if (result.status === 400) {
                    console.log('fail 400');
                }
            })
            .catch(function (error) {
                console.log('Request failed', error);
            });
    }, [props.isAdmin]);

    //retrieving logo and url info for the job's orgs
    useEffect(() => {
        if (job) {
            let orgNamesInternal = [
                ...job.leadManagers,
                ...job.coManagers,
                ...job.municipalAdvisors,
                ...job.counsels
            ];
            HttpHelpers.postJsonParseJson(
                constants.urlPrefix + '/job/orgs',
                { orgNames: orgNamesInternal },
                props.accessToken,
                refreshTokens
            ).then(result => {
                if (result.status === 200) {
                    let resultOrgsAndLogos = result.body.orgsAndLogos;
                    let orgsInternal: Org[] = [];
                    resultOrgsAndLogos.forEach(resultOrgAndLogo => {
                        orgsInternal.push({
                            name: resultOrgAndLogo.org.name,
                            url: resultOrgAndLogo.org.url,
                            logo: resultOrgAndLogo.logoOption
                        });
                    });
                    setOrgs(orgsInternal);
                }
            });
        }
    }, [props.isAdmin, job]);

    const maybeEditJobButton = () => {
        if (!props.isAdmin) return null;
        return (
            <Tooltip title={'Edit Job'} arrow>
                <Fab
                    color='primary'
                    aria-label='edit'
                    onClick={() => history.push('/edit/' + jobId)}
                    size={'small'}>
                    <EditIcon />
                </Fab>
            </Tooltip>
        );
    };

    const maybeDelete = () => {
        if (!props.isAdmin) return null;
        return (
            <DeleteButton
                onClick={() => {
                    setIsDeleteConfirmationOpen(true);
                }}
                label={undefined}
            />
        );
    };

    const maybeViewTracking = () => {
        if (!props.isAdmin && !isOwner) return null;
        return (
            <Fab
                aria-label='tracking'
                onClick={() => history.push('/stats/' + jobId)}
                size={'small'}
                variant={'extended'}>
                <BarChartIcon />
                Track Views
            </Fab>
        );
    };

    const handleDownload = () => {
        HttpHelpers.postJsonParseJson(
            constants.urlPrefix + '/job/download',
            {
                jobId: jobId,
                filename: selectedFileName
            },
            props.accessToken,
            refreshTokens
        )
            .then(result => {
                if (result.status === 200) {
                    let a = document.createElement('a');
                    a.href = result.body;
                    a.target = '_blank';
                    a.click();
                }
            })
            .finally(() => setDisclaimerOpen(false));
    };

    const maybeJobTitle = () => {
        if (job) {
            return <Markup content={job.title} />;
        }
        return null;
    };

    const getDownloadButtonsForFiles = f => {
        return (
            <Box className={classes.topMargin}>
                <Grid item container justifyContent={'center'}>
                    {f.map(file => (
                        <Grid item key={file.fileName}>
                            <Button
                                variant='contained'
                                color='primary'
                                className={classes.button}
                                startIcon={
                                    <CloudDownloadIcon>
                                        download
                                    </CloudDownloadIcon>
                                }
                                onClick={() => {
                                    setSelectedFileName(file.fileName);
                                    setDisclaimerOpen(true);
                                }}>
                                {file.displayName}
                            </Button>
                        </Grid>
                    ))}
                </Grid>
            </Box>
        );
    };

    const maybeDownloadButtons = () => {
        if (job && job.files.length) {
            let nonRoadshowJobFiles = job.files.filter(
                f => f.category !== DocUploadControlFileEntryType.Roadshow
            );
            let finalDocs = nonRoadshowJobFiles.filter(
                f => f.category === DocUploadControlFileEntryType.FinalDoc
            );
            let nonFinalDocs = nonRoadshowJobFiles.filter(
                f => f.category !== DocUploadControlFileEntryType.FinalDoc
            );
            if (
                job.isFinal &&
                !props.isAdmin &&
                !isOwner &&
                finalDocs.length > 0
            ) {
                return <>{getDownloadButtonsForFiles(finalDocs)}</>;
            }
            return (
                <>
                    {finalDocs.length > 0 &&
                        getDownloadButtonsForFiles(finalDocs)}
                    {nonFinalDocs.length > 0 &&
                        getDownloadButtonsForFiles(nonFinalDocs)}
                </>
            );
        } else return null;
    };

    const maybeBondLink = () => {
        if (job && job.bondLinkUrl !== null && job.bondLinkUrl !== undefined) {
            //TODO do we want this to be included in our tracking?
            return (
                <span>
                    <br />
                    <a target='_blank' href={job.bondLinkUrl}>
                        View Document via BondLink
                    </a>
                </span>
            );
        } else return null;
    };

    const disclaimerText = () => {
        if (job && job.disclaimerOverride) return job.disclaimerOverride;
        else
            return (
                'The Issuer has prepared this offering document (the “Offering Document”) relating to its issuance of securities as of its date.\n' +
                '\n' +
                'The Offering Document and the information contained therein speak only as of its date and are subject to amendment or other change without any notice. Its availability on this website is not an implication that the information contained in such Offering Document is updated from and after its dated date.\n' +
                '\n' +
                'Under no circumstances shall the Offering Document or its electronic posting constitute an offer to sell or the solicitation of any offer to buy nor will there be any sale of the securities described in the Offering Document in any jurisdiction in which such offer, solicitation or sale would be unlawful prior to registration or qualification under the securities laws of any such jurisdiction. Investors must read the entire Offering Document, including the appendices, to obtain information essential to making an informed investment decision.\n' +
                '\n' +
                'By clicking accept and accessing the Offering Document, you will be deemed to have accepted the terms of this Disclaimer and to have consented to the electronic transmission of the Offering Document.\n' +
                ' \n' +
                'Access to the electronic version of the Offering Document will continue to be provided on behalf of the Issuer by means of the hyperlink at the bottom of this page. The hyperlink at the bottom of this page, a service procured by the Issuer from McElwee & Quinn, is the sole means by which the Issuer has provided access to the electronic version of the Offering Document.\n' +
                '\n' +
                'If you need assistance accessing the Offering Document attached hereto, please email McElwee & Quinn at mary.mcelwee@mcelweequinn.com or call (856) 349-7686.\n'
            );
    };

    const jobTypeDisplay = () => {
        if (job)
            return (
                <Box className={classes.jobType}>
                    {job.isFinal ? '' : 'PRELIMINARY '}
                    {job.type.toUpperCase()}
                </Box>
            );
        else return null;
    };

    const maybeAmount = () => {
        if (job && job.amount)
            return (
                <Box className={classes.amount}>
                    {currencyFormatter.format(Number(job.amount))}
                </Box>
            );
        else return null;
    };
    const workingGroupSectionStyle = () => {
        if (props.isAdmin) return { paddingTop: '48px' };
        else return { paddingTop: '0px' };
    };

    const mainSectionStyle = () => {
        if (props.isAdmin) return {};
        else return { paddingTop: '24px' };
    };

    return (
        <>
            <Container maxWidth='xl'>
                <Grid
                    container
                    spacing={4}
                    style={{ minHeight: '60vh' }}
                    justifyContent={'space-evenly'}>
                    <Grid item xs={3}></Grid>
                    <Grid item xs={6} style={mainSectionStyle()}>
                        <div className={classes.fab}>
                            {maybeEditJobButton()}
                            {maybeDelete()}
                            {maybeViewTracking()}
                        </div>
                        <Paper className={classes.paper} elevation={3}>
                            <Box
                                textAlign={'center'}
                                pt={{ xs: 0, sm: 0 }}
                                pb={{ xs: 5, sm: 0 }}>
                                <Typography
                                    variant='h4'
                                    className={classes.header}>
                                    {jobTypeDisplay()}
                                    {maybeAmount()}
                                    <Box className={classes.issuer}>
                                        {job ? job.issuer : null}
                                    </Box>
                                </Typography>
                                <Typography variant='h6'>
                                    {maybeJobTitle()}
                                </Typography>
                                <br />
                                <Typography
                                    variant={'subtitle1'}
                                    style={{ whiteSpace: 'pre-wrap' }}>
                                    {job ? job.description : null}
                                </Typography>
                                {maybeBondLink()}
                            </Box>
                        </Paper>
                        {maybeDownloadButtons()}
                        {job && job.roadshowMetas && job.roadshowMetas.length > 0 && !job.isFinal ? (
                            <RoadshowButtonList
                                job={job}
                                selectedRoadshowIdx={selectedRoadshowIdx}
                                setVisibleRoadshowIdx={setSelectedRoadshowIdx}
                                roadShowDisclaimerAccepted={
                                    roadshowDisclaimerAccepted
                                }
                                setRoadshowDisclaimerOpen={
                                    setRoadshowDisclaimerOpen
                                }
                                setRoadshowDiclaimerBlockingIdx={setRoadshowDisclaimerBlockingIdx}
                            />
                        ) : null}
                    </Grid>
                    <Grid item xs={3} style={workingGroupSectionStyle()}>
                        {job && orgs && orgs.length > 0 ? (
                            <>
                                <OrganizationListPaper
                                    orgType='leadManagers'
                                    job={job}
                                    orgs={orgs}
                                />
                                <OrganizationListPaper
                                    orgType='coManagers'
                                    job={job}
                                    orgs={orgs}
                                />
                                <OrganizationListPaper
                                    orgType='municipalAdvisors'
                                    job={job}
                                    orgs={orgs}
                                />
                                <OrganizationListPaper
                                    orgType='counsels'
                                    job={job}
                                    orgs={orgs}
                                />
                            </>
                        ) : null}
                    </Grid>
                </Grid>
                {job && job.roadshowMetas && job.roadshowMetas.length > 0 && selectedRoadshowIdx || selectedRoadshowIdx === 0 && !job.isFinal ? (
                    <RoadshowView
                        job={job}
                        roadshowType={job.roadshowMetas[selectedRoadshowIdx]?.roadshowType}
                        selectedRoadshowIdx={selectedRoadshowIdx}
                        accessToken={props.accessToken}
                        refreshTokensCallback={props.refreshTokensCallback}
                    />
                ) : null}
                {/* Roadshow Disclaimer Modal */}
                <Dialog
                    open={roadshowDisclaimerOpen}
                    onClose={() => setRoadshowDisclaimerOpen(false)}
                    aria-labelledby='roadshow- disclaimer-title'
                    aria-describedby='roadshow-disclaimer-text'>
                    <DialogTitle id={'roadshow-disclaimer-title'}>
                        Disclaimer
                    </DialogTitle>
                    <Box m={3}>
                        <div style={{ whiteSpace: 'pre-line' }}>
                            <DialogContentText id={'roadshow-disclaimer-text'}>
                                {disclaimerText()}
                            </DialogContentText>
                        </div>
                    </Box>
                    <DialogActions>
                        <Button
                            onClick={() => {
                                setRoadshowDisclaimerOpen(false);
                                setRoadshowDisclaimerAccepted(true);
                                setSelectedRoadshowIdx(roadshowDisclaimerBlockingIdx);
                            }}
                            color={'primary'}>
                            Accept
                        </Button>
                        <Button
                            onClick={() => setRoadshowDisclaimerOpen(false)}
                            color={'primary'}>
                            Decline
                        </Button>
                    </DialogActions>
                </Dialog>
                <Modal
                    open={isDeleteConfirmationOpen}
                    onClose={() => {
                        setIsDeleteConfirmationOpen(false);
                    }}>
                    <Box
                        sx={{
                            position: 'absolute',
                            left: '50%',
                            top: '50%',
                            // @ts-ignore
                            transform: 'translate(-50%, -50%)',
                            padding: '20px',
                            backgroundColor: 'white',
                            borderRadius: '10px',
                            outline: 'none'
                        }}>
                        <Typography
                            id='modal-modal-title'
                            variant='h6'
                            component='h3'>
                            Are you sure you want to delete this offering?
                        </Typography>
                        <div style={{ textAlign: 'center', marginTop: '10px' }}>
                            <Button
                                style={{ margin: '10px' }}
                                variant='contained'
                                color='primary'
                                onClick={() => deleteJob()}>
                                Yes
                            </Button>
                            <Button
                                style={{ margin: '10px' }}
                                variant='contained'
                                color='primary'
                                onClick={() => {
                                    setIsDeleteConfirmationOpen(false);
                                }}>
                                Cancel
                            </Button>
                        </div>
                    </Box>
                </Modal>
            </Container>
            <Dialog
                open={disclaimerOpen}
                onClose={() => setDisclaimerOpen(false)}
                aria-labelledby='disclaimer-title'
                aria-describedby='disclaimer-text'>
                <DialogTitle id={'disclaimer-title'}>Disclaimer</DialogTitle>
                <Box m={3}>
                    <div style={{ whiteSpace: 'pre-line' }}>
                        <DialogContentText id={'disclaimer-text'}>
                            {disclaimerText()}
                        </DialogContentText>
                    </div>
                </Box>
                <DialogActions>
                    <Button onClick={handleDownload} color={'primary'}>
                        Accept
                    </Button>
                    <Button
                        onClick={() => setDisclaimerOpen(false)}
                        color={'primary'}>
                        Decline
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};


    interface IRoadshowButtonListProps {
        job?: Job;
        selectedRoadshowIdx?: number;
        setVisibleRoadshowIdx: React.Dispatch<React.SetStateAction<number | undefined>>;
        roadShowDisclaimerAccepted: boolean;
        setRoadshowDisclaimerOpen: React.Dispatch<
            React.SetStateAction<boolean>
        >;
        setRoadshowDiclaimerBlockingIdx: React.Dispatch<React.SetStateAction<number | undefined>>;
    }

    const RoadshowButtonList: React.FunctionComponent<
        IRoadshowButtonListProps
    > = ({
            job, 
            selectedRoadshowIdx, 
            setVisibleRoadshowIdx, 
            roadShowDisclaimerAccepted, 
            setRoadshowDisclaimerOpen,
            setRoadshowDiclaimerBlockingIdx
        }: IRoadshowButtonListProps): React.ReactElement => {
            if (!job) return null;
            const classes = useStyles();
            const getDisplayName = (idx: number): string => {
                const meta = job.roadshowMetas[idx];
                const file = job.associatedFiles?.entries.find(e => e.fileName === meta.fileName);
                return file ? file.displayName : `Roadshow ${idx + 1}`;
            }
            return (
                <>
                    {
                        job.roadshowMetas.map((_roadshowMeta, idx) => (
                            <Box className={classes.topMargin} key={idx}>
                                <Grid item container justifyContent='center'>
                                    <Button
                                        variant='contained'
                                        color='primary'
                                        className={classes.button}
                                        startIcon={<SlideshowIcon>show roadshow</SlideshowIcon>}
                                        onClick={() => {
                                            if (!roadShowDisclaimerAccepted) {
                                                setRoadshowDisclaimerOpen(true);
                                                setRoadshowDiclaimerBlockingIdx(idx);
                                            } else {
                                                if (selectedRoadshowIdx === idx) {
                                                    setVisibleRoadshowIdx(undefined);
                                                } else {
                                                    setVisibleRoadshowIdx(idx);
                                                }
                                            }
                                        }}>
                                        {selectedRoadshowIdx === idx ? 'Hide' : 'View'} {getDisplayName(idx)}
                                    </Button>
                                </Grid>
                            </Box>
                        ))
                    }
                </>
            )
    };

    interface IRoadshowViewProps {
        job: Job;
        roadshowType: RoadshowType;
        selectedRoadshowIdx: number;
        accessToken: string;
        refreshTokensCallback: (history: H.History<H.LocationState>) => void;
    }
    const RoadshowView: React.FunctionComponent<IRoadshowViewProps> = ({
        job,
        roadshowType,
        selectedRoadshowIdx,
        accessToken,
        refreshTokensCallback
    }: IRoadshowViewProps): React.ReactElement => {
        return roadshowType === 'video' ? (
            <RoadshowVideoPlayer job={job} selectedRoadshowIdx={selectedRoadshowIdx} />
        ) : roadshowType === 'pdf' ? (
            <RoadshowSlidePlayer
                job={job}
                selectedRoadshowIdx={selectedRoadshowIdx}
                accessToken={accessToken}
                refreshTokensCallback={refreshTokensCallback}
            />
        ) : null;
    };
