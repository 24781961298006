import * as React from 'react';
import Backdrop from '@material-ui/core/Backdrop';
import Box from '@material-ui/core/Box';
import Modal from '@material-ui/core/Modal';
import Fade from '@material-ui/core/Fade';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { Signin } from './Signin';
import { Dialog, DialogContent, DialogTitle } from '@material-ui/core';

interface IProps {
    open: boolean;
    onClose: () => void;
    onSignin: (
        username: string,
        password: string,
        history: any
    ) => Promise<void>;
}

export default function TransitionsModal(props: IProps) {
    return (
        <Dialog open={props.open} onClose={props.onClose}>
            <DialogContent>
                <Signin
                    onSignin={props.onSignin}
                    customStyle={{}}
                    closeModal={props.onClose}
                />
            </DialogContent>
        </Dialog>
    );
}
