import { constants } from './constants';

class HttpHelpers {
    static __getAccessToken(
        accessTokenOption: any,
        refreshTokensCallback: () => void | null
    ): string | null {
        if (accessTokenOption) return accessTokenOption;
        else {
            if (refreshTokensCallback !== null) refreshTokensCallback();
            else return null;
        }
    }

    static __addAuthHeader(
        headers: Headers,
        accessTokenOption: string | null,
        refreshTokensCallback: () => void | null
    ): Headers {
        const auth: string =
            'Bearer ' +
            HttpHelpers.__getAccessToken(
                accessTokenOption,
                refreshTokensCallback
            );
        headers.append('Authorization', auth.toString());
        return headers;
    }

    static buildUrl(url: string) {
        return constants.host + url;
    }

    static postJsonUnauthenticated(
        url: string,
        requestBody: any
    ): Promise<Response> {
        let headers = new Headers();
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');
        console.log('posting to ', url);
        return fetch(HttpHelpers.buildUrl(url), {
            method: 'POST',
            headers: headers,
            credentials: 'include',
            body: JSON.stringify(requestBody)
        });
    }

    private static __methodJson(
        url: string,
        requestBody: any,
        accessTokenOption: string | null,
        refreshTokensCallback: () => void | null,
        requestMethod: string = 'POST'
    ): Promise<Response> {
        let headers = new Headers();
        HttpHelpers.__addAuthHeader(
            headers,
            accessTokenOption,
            refreshTokensCallback
        );
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');

        return fetch(HttpHelpers.buildUrl(url), {
            method: requestMethod,
            headers: headers,
            credentials: 'include',
            body: JSON.stringify(requestBody)
        });
    }

    static postJson(
        url: string,
        requestBody: any,
        accessTokenOption: string | null,
        refreshTokensCallback: () => void | null
    ): Promise<Response> {
        return HttpHelpers.__methodJson(
            url,
            requestBody,
            accessTokenOption,
            refreshTokensCallback
        );
    }

    static putJson(
        url: string,
        requestBody: any,
        accessTokenOption: string | null,
        refreshTokensCallback: () => void | null
    ): Promise<Response> {
        return HttpHelpers.__methodJson(
            url,
            requestBody,
            accessTokenOption,
            refreshTokensCallback,
            'PUT'
        );
    }

    static postJsonParseJson(
        url: string,
        requestBody: any,
        accessTokenOption: string | null,
        refreshTokensCallback: () => void | null
    ) {
        return HttpHelpers.postJson(
            url,
            requestBody,
            accessTokenOption,
            refreshTokensCallback
        ).then(response => {
            return response.json().then(j => {
                return { body: j, status: response.status };
            });
        });
    }

    static postFormData(
        url: string,
        formData: FormData,
        accessTokenOption: string | null,
        refreshTokensCallback: () => void | null
    ) {
        let headers = new Headers();

        HttpHelpers.__addAuthHeader(
            headers,
            accessTokenOption,
            refreshTokensCallback
        );

        return fetch(HttpHelpers.buildUrl(url), {
            method: 'POST',
            body: formData,
            headers: headers,
            credentials: 'include'
        }).then(function (response) {
            return response.json().then(j => {
                return { body: j, status: response.status };
            });
        });
    }

    static __getJson(url: string, headers: Headers) {
        return fetch(HttpHelpers.buildUrl(url), {
            method: 'GET',
            headers: headers
        }).then(function (response) {
            return response.json().then(j => {
                return { body: j, status: response.status };
            });
        });
    }

    static __getBlob(url: string, headers: Headers) {
        return fetch(HttpHelpers.buildUrl(url), {
            method: 'GET',
            headers: headers
        }).then(function (response) {
            return response.blob().then(blob => {
                return { body: blob, status: response.status };
            });
        });
    }

    static __delete(url: string, headers: Headers) {
        return fetch(HttpHelpers.buildUrl(url), {
            method: 'DELETE',
            headers: headers
        }).then(function (response) {
            return response.json().then(j => {
                return { body: j, status: response.status };
            });
        });
    }

    static getJson(
        url: string,
        authenticated: boolean,
        accessTokenOption: string | null,
        refreshTokensCallback: () => void | null
    ) {
        let headers = new Headers();
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');

        if (authenticated === true) {
            HttpHelpers.__addAuthHeader(
                headers,
                accessTokenOption,
                refreshTokensCallback
            );
        }

        return HttpHelpers.__getJson(url, headers);
    }

    static getBlob(
        url: string,
        authenticated: boolean,
        accessTokenOption: string | null,
        refreshTokensCallback: () => void | null
    ) {
        let headers = new Headers();
        // headers.append('Accept', 'application/json');
        // headers.append('Content-Type', 'application/json');

        if (authenticated === true) {
            HttpHelpers.__addAuthHeader(
                headers,
                accessTokenOption,
                refreshTokensCallback
            );
        }

        return HttpHelpers.__getBlob(url, headers);
    }

    static deleteJson(
        url: string,
        authenticated: boolean,
        accessTokenOption: string | null,
        refreshTokensCallback: () => void | null
    ) {
        let headers = new Headers();
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');

        if (authenticated === true) {
            HttpHelpers.__addAuthHeader(
                headers,
                accessTokenOption,
                refreshTokensCallback
            );
        }

        return HttpHelpers.__delete(url, headers);
    }

    static __getNonJson(url: string, headers: Headers) {
        return fetch(HttpHelpers.buildUrl(url), {
            method: 'GET',
            headers: headers
        });
    }

    static getNonJson(
        url: string,
        authenticated: boolean,
        accessTokenOption: string | null,
        refreshTokensCallback: () => void | null
    ) {
        let headers = new Headers();

        if (authenticated === true) {
            HttpHelpers.__addAuthHeader(
                headers,
                accessTokenOption,
                refreshTokensCallback
            );
        }

        return HttpHelpers.__getNonJson(url, headers);
    }

    //static handle401()
}
export { HttpHelpers };

function requestBody(
    url: () => string | undefined,
    requestBody: any,
    accessTokenOption: any
) {
    throw new Error('Function not implemented.');
}
