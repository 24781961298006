import * as React from 'react';
import { useEffect, useState } from 'react';
import {
    Box,
    Fab,
    IconButton,
    makeStyles,
    Theme,
    Typography
} from '@material-ui/core';
import PictureAsPdfTwoToneIcon from '@material-ui/icons/PictureAsPdfTwoTone';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import clsx from 'clsx';

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        '& > *': {
            margin: theme.spacing(1)
        }
    },
    input: {
        display: 'none'
    },
    uploadButton: {
        color: theme.palette.primary.light
    },
    uploadTable: {
        display: 'table',
        marginTop: '15px',
        marginBottom: '15px',
        fontFamily: theme.typography.fontFamily,
        fontSize: theme.typography.fontSize,
        borderSpacing: '0',
        border: '1px solid silver',
        width: '100%'
    },
    uploadHeaderColumn: {
        display: 'table-cell',
        textAlign: 'left',
        paddingTop: '7px',
        paddingBottom: '7px',
        paddingLeft: '10px',
        paddingRight: '10px'
    },
    uploadHeaderRow: {
        display: 'table-row',
        backgroundColor: theme.palette.secondary.main
    },
    uploadColumn: {
        display: 'table-cell',
        paddingTop: '2px',
        paddingBottom: '0px',
        paddingLeft: '10px',
        paddingRight: '10px'
    },
    uploadRow: {
        display: 'table-row'
    },
    uploadRowEven: {
        display: 'table-row',
        backgroundColor: '#f9f9f9'
    },
    uploadRowInput: {
        paddingTop: '7px',
        paddingBottom: '7px',
        paddingLeft: '10px',
        paddingRight: '10px'
    }
}));

interface IDocUploadCtrlProps {
    formData: FormData;
    fileEntries: DocUploadControlFileEntry[];
    existingFiles: DocUploadControlFileEntry[];
    setFileEntries: (files: DocUploadControlFileEntry[]) => void;
    docCount: number;
}

//acts as an enum (not an actual enum because we need to be iterable)
export const DocUploadControlFileEntryType = {
    Notice: 'notice',
    Prelim: 'prelim',
    FinalDoc: 'finalDoc',
    Roadshow: 'roadshow',
    RFP: 'rfp',
    Supplement: 'supplement',
    Disclosure: 'disclosure',
    Rating: 'rating'
};

export interface DocUploadControlFileEntry {
    category: string;
    displayName?: string;
    fileName: string;
    show: boolean;
    order: number;
}

const DocUploadCtrl: React.FunctionComponent<IDocUploadCtrlProps> = (
    props: IDocUploadCtrlProps
): React.ReactElement => {
    const classes = useStyles();
    const [files, setFiles] = useState<Array<DocUploadControlFileEntry>>([]);
    const [fileName, setFileName] = useState(null);

    const removeFile = (file: DocUploadControlFileEntry) => {
        var newFiles = files.filter(f => f.fileName !== file.fileName);
        setFiles(newFiles);
        props.setFileEntries(newFiles);

        var fData = props.formData.getAll('files');
        props.formData.delete('files');
        fData.forEach(f => {
            //@ts-ignore
            if (f.name !== file.fileName) {
                //@ts-ignore
                props.formData.append('files', f, f.name);
            }
        });
    };

    const handleCapture: React.ChangeEventHandler<HTMLInputElement> = ({
        target
    }: React.ChangeEvent<HTMLInputElement>) => {
        var newFiles: DocUploadControlFileEntry[] = [];
        for (var ii = 0; ii < target.files.length; ii++) {
            var file = target.files[ii];
            if (false) {
                //if(file.size >= 100000000) {
                alert('File size exceeds limit of 100MB.');
            } else {
                newFiles.push({
                    category: '',
                    displayName: undefined,
                    fileName: file.name,
                    show: true,
                    order: files.length + ii
                });
                props.formData.append('files', file, file.name);
            }
        }
        setFiles(files.concat(newFiles));
        props.setFileEntries(files.concat(newFiles));
        setFileName('');
    };

    useEffect(() => {
        if (typeof props.existingFiles !== 'undefined') {
            setFiles(props.existingFiles);
            props.setFileEntries(props.existingFiles);
        }
    }, [props.existingFiles]);

    const updateFile = (file, fieldName, value) => {
        var updatedFiles = files.map(f => {
            if (f.fileName === file.fileName && f.order === file.order) {
                return { ...f, [fieldName]: value };
            } else {
                return f;
            }
        });
        setFiles(updatedFiles);
        props.setFileEntries(updatedFiles);
    };

    const fileList = () => {
        var list = files.map((f, index) => (
            <div
                key={index}
                className={
                    index % 2 === 0 ? classes.uploadRow : classes.uploadRowEven
                }>
                <div className={classes.uploadColumn}>
                    <IconButton
                        onClick={() => {
                            removeFile(f);
                        }}>
                        <RemoveCircleOutlineIcon />
                    </IconButton>
                </div>
                <div
                    className={classes.uploadColumn}
                    style={{ maxWidth: '350px', overflow: 'scroll' }}>
                    {f.fileName}
                </div>
                <div className={classes.uploadColumn}>
                    <select
                        required
                        className={classes.uploadRowInput}
                        value={f.category}
                        onChange={({
                            target: { value }
                        }: React.ChangeEvent<HTMLSelectElement>) =>
                            updateFile(f, 'category', value)
                        }>
                        <option value=''>Select category...</option>
                        {Object.keys(DocUploadControlFileEntryType).map(
                            (key, idx) => (
                                <option
                                    key={idx}
                                    value={DocUploadControlFileEntryType[key]}>
                                    {key}
                                </option>
                            )
                        )}
                    </select>
                </div>
                <div className={classes.uploadColumn}>
                    <input
                        type='text'
                        className={classes.uploadRowInput}
                        value={f.displayName ? f.displayName : ''}
                        size={30}
                        onChange={event => {
                            updateFile(
                                f,
                                'displayName',
                                event.target.value.trim() !== ''
                                    ? event.target.value
                                    : undefined
                            );
                        }}
                    />
                </div>
            </div>
        ));
        return list;
    };

    const filesSection = () => {
        if (files.length > 0)
            return (
                <Box my={1} className={classes.uploadTable}>
                    <div className={classes.uploadHeaderRow}>
                        <div
                            style={{ width: '10%' }}
                            className={classes.uploadHeaderColumn}>
                            Remove
                        </div>
                        <div
                            style={{ width: '30%' }}
                            className={classes.uploadHeaderColumn}>
                            Filename
                        </div>
                        <div
                            style={{ width: '30%' }}
                            className={classes.uploadHeaderColumn}>
                            Category
                        </div>
                        <div
                            style={{ width: '30%' }}
                            className={classes.uploadHeaderColumn}>
                            Display Name
                        </div>
                    </div>
                    {fileList()}
                </Box>
            );
        else
            return (
                <Box my={2}>
                    <Typography variant='h6' color='primary'>
                        No Files Uploaded
                    </Typography>
                </Box>
            );
    };

    return (
        <Box m={1} my={2}>
            <Fab
                className={clsx(classes.uploadButton)}
                aria-label='upload file'
                component={'label'}
                variant={'extended'}>
                <input
                    accept='application/pdf, video/mp4, text/csv, application/zip'
                    className={classes.input}
                    type='file'
                    id='fileSelector'
                    hidden
                    onChange={handleCapture}
                    value={fileName}
                    onClick={() => {
                        setFileName(null);
                    }}
                />
                <PictureAsPdfTwoToneIcon fontSize='large' />
                Upload Files
            </Fab>
            {filesSection()}
        </Box>
    );
};

export { DocUploadCtrl };
