import React from 'react';
import {
    Box,
    Paper,
    Theme,
    Typography,
    Link,
    createStyles,
    makeStyles
} from '@material-ui/core';
import clsx from 'clsx';
import { Job, Org } from './JobView';

interface IImageListPaperProps {
    orgType: string;
    job: Job;
    orgs: Array<Org>;
}

const orgTypeToPaperTitleMap = {
    leadManagers: 'Lead Managers',
    coManagers: 'Co-Managers',
    municipalAdvisors: 'Municipal Advisors',
    counsels: 'Counsels'
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        paper: {
            padding: theme.spacing(2),
            textAlign: 'center',
            color: theme.palette.text.secondary
        },
        header: {
            color: theme.palette.primary.main,
            paddingBottom: '20px'
        },
        topMargin: {
            marginTop: theme.spacing(3)
        },
        logo: {
            maxWidth: '225px',
            maxHeight: '60px',
            display: 'block',
            marginLeft: 'auto',
            marginRight: 'auto',
            marginBottom: '40px'
        },
        link: {
            textDecoration: 'none'
        }
    })
);

const OrganizationListPaper: React.FC<IImageListPaperProps> = ({
    orgType,
    job,
    orgs
}) => {
    const classes = useStyles();

    return job[orgType] && job[orgType].length > 0 ? (
        <Paper className={clsx(classes.paper, classes.topMargin)} elevation={3}>
            <Typography variant='h5' className={classes.header}>
                {orgTypeToPaperTitleMap[orgType]}
            </Typography>
            <br />
            <div style={{ overflow: 'hidden' }}>
                {(() => {
                    const organizations: Array<Org> = job[orgType]
                        .map(orgName => orgs.find(org => org.name === orgName))
                        .filter(Boolean);

                    const conditionalLink = (
                        org: Org,
                        child: React.ReactElement,
                        key: number
                    ) => {
                        return org.url ? (
                            <Link
                                href={org.url}
                                target='_blank'
                                key={org.name + key}
                                color='inherit'
                                className={classes.link}>
                                {child}
                            </Link>
                        ) : (
                            child
                        );
                    };

                    const getTypography = (org: Org, key: number) => (
                        <Box sx={{ marginY: 4 }}>
                            <Typography variant='h6' key={org.name + key}>
                                {org.name}
                            </Typography>
                        </Box>
                    );

                    if (organizations.length <= 4) {
                        return organizations.map((org, i) =>
                            conditionalLink(
                                org,
                                org?.logo ? (
                                    <img
                                        key={org.name + i}
                                        src={org.logo}
                                        alt={org.name}
                                        className={classes.logo}
                                    />
                                ) : (
                                    getTypography(org, i)
                                ),
                                i
                            )
                        );
                    } else {
                        return organizations.map((org, i) =>
                            conditionalLink(org, getTypography(org, i), i)
                        );
                    }
                })()}
            </div>
        </Paper>
    ) : null;
};
export default OrganizationListPaper;
