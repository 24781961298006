import * as React from 'react';
import { useEffect, useState } from 'react';
import './../assets/scss/App.scss';
import '../components/types/AuthJwtProps';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import AddIcon from '@material-ui/icons/Add';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import { useHistory } from 'react-router-dom';
import TableHead from '@material-ui/core/TableHead';
import Container from '@material-ui/core/Container';
import StateMultiSelect from './StateMultiSelect';
import Button from '@material-ui/core/Button';
import PreliminaryOrFinal from './PreliminaryOrFinal';
import NegotiatedOrCompetitive from './NegotiatedOrCompetitive';
import Fab from '@material-ui/core/Fab';
import { HttpHelpers } from './HttpHelpers';
import { constants } from './constants';
import { Box, TextField, Tooltip } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { formatDate, getJobType } from './helpers/Helpers';
import SigninModal from './SigninModal';
import MyJobs from './MyJobs';
import { Business } from '@material-ui/icons';

interface Column {
    id:
        | 'jobNumber'
        | 'type'
        | 'state'
        | 'amount'
        | 'issuer'
        | 'description'
        | 'leadManagers'
        | 'municipalAdvisors'
        | 'postedDate';
    label: string;
    minWidth?: string;
    maxWidth?: string;
    align?: 'center';
    format?: (value: any) => any;
}

const currencyFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits: 0
});

const orgFormatter = (value: string[]) => {
    return value.map((v, key) => <p key={key}>{v}</p>);
};

const columns: Column[] = [
    { id: 'jobNumber', label: 'Job Number', minWidth: '85px' },
    { id: 'type', label: 'Type', minWidth: '100px' },
    { id: 'state', label: 'State' },
    {
        id: 'amount',
        label: 'Amount',
        format: (value: number) =>
            value > 0 ? currencyFormatter.format(value) : ''
    },
    { id: 'issuer', label: 'Issuer' },
    { id: 'description', label: 'Description' },
    {
        id: 'leadManagers',
        label: 'Lead Managers',
        format: (value: string[]) => orgFormatter(value)
    },
    { id: 'municipalAdvisors', label: 'Municipal Advisors' },
    { id: 'postedDate', label: 'Date Posted' }
];

export interface Data {
    key: string;
    jobNumber: string;
    type: string;
    state: string;
    amount: number;
    issuer: string;
    description: string;
    leadManagers: string[];
    municipalAdvisors: string[];
    postedDate: string; //TODO: use date type?
}

function createData(
    key,
    jobNumber,
    type,
    state,
    amount,
    issuer,
    description,
    leadManagers,
    municipalAdvisors,
    postedDate
): Data {
    return {
        key,
        jobNumber,
        type,
        state,
        amount,
        issuer,
        description,
        leadManagers,
        municipalAdvisors,
        postedDate
    };
}

const useStyles2 = makeStyles(theme =>
    createStyles({
        tableContainer: {
            maxWidth: 1500
        },
        table: {
            minWidth: 500
        },
        filterControl: {
            paddingBottom: '8px'
        },
        prelimTag: {
            borderRadius: '4px',
            width: '45px',
            textAlign: 'center',
            display: 'inline-block',
            fontSize: '12px',
            fontWeight: 'bold',
            padding: '2px',
            paddingBottom: '1px',
            backgroundColor: theme.palette.secondary.main,
            color: 'white'
        },
        finalTag: {
            borderRadius: '4px',
            width: '45px',
            textAlign: 'center',
            display: 'inline-block',
            fontSize: '12px',
            fontWeight: 'bold',
            padding: '2px',
            paddingBottom: '1px',
            backgroundColor: theme.palette.primary.main,
            color: 'white'
        },
        competitiveTag: {
            borderRadius: '4px',
            textAlign: 'center',
            display: 'inline-block',
            fontSize: '12px',
            fontWeight: 'bold',
            padding: '2px',
            paddingBottom: '1px',
            backgroundColor: 'black',
            color: 'white',
            paddingLeft: '4px',
            paddingRight: '4px',
            marginLeft: '4px'
        },
        adminTag: {
            borderRadius: '4px',
            textAlign: 'center',
            display: 'inline-block',
            fontSize: '12px',
            fontWeight: 'bold',
            padding: '2px',
            paddingBottom: '1px',
            backgroundColor: '#ff3d3d',
            color: 'white',
            paddingLeft: '4px',
            paddingRight: '4px',
            marginLeft: '4px'
        }
    })
);

interface IProps {
    username: string;
    isAdmin: boolean;
    accessToken: string;
    refreshTokensCallback: (history: History) => void;
    clearSearch: boolean;
    setClearSearch: any;
    setSearchValue: any;
    searchValue: string;
    setInputValue: any;
    inputValue: string;
    onSignin: (
        username: string,
        password: string,
        history: any
    ) => Promise<void>;
    page: number;
    setPage: (page: number) => void;
    rowsPerPage: number;
    setRowsPerPage: (rowsPerPage: number) => void;
    prelimOrFinalValue: string;
    setPrelimOrFinalValue: (prelimOrFinal: string) => void;
    negOrCompValue: string;
    setNegOrCompValue: (negOrComp: string) => void;
    stateName: string[];
    setStateName: (states: string[]) => void;
    jobNumberToSearch: string;
    setJobNumberToSearch: (jobNumber: string) => void;
    resetToggles: () => void;
    clearSearchAndResetPage: () => void;
}

export default function CustomPaginationActionsTable(props: IProps) {
    const history = useHistory();

    const refreshTokens = () => {
        props.refreshTokensCallback(history);
    };

    const { accessToken } = props;

    //My jobs toggle control
    const [myJobsToggleValue, setMyJobsToggleValue] = useState(null);
    const onMyJobsToggleChange = (event, value) => {
        props.clearSearchAndResetPage();
        props.setJobNumberToSearch('');
        setMyJobsToggleValue(value);
    };

    const onJobNumberChange = event => {
        props.clearSearchAndResetPage();
        props.resetToggles();
        props.setJobNumberToSearch(event.target.value);
    };

    const onStateChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        props.clearSearchAndResetPage();
        props.setJobNumberToSearch('');
        props.setStateName(event.target.value as string[]);
    };

    const onNegOrCompChange = (event, newType) => {
        props.clearSearchAndResetPage();
        props.setJobNumberToSearch('');
        props.setNegOrCompValue(newType);
    };

    const onPrelimOrFinalChange = (event, newStatus) => {
        props.clearSearchAndResetPage();
        props.setJobNumberToSearch('');
        props.setPrelimOrFinalValue(newStatus);
    };

    const classes = useStyles2();
    const [isDataLoading, setIsDataLoading] = useState(false);
    const [rows, setRows] = useState([]);
    const [jobs, setJobs] = useState({});
    const [totalRows, setTotalRows] = useState(0);

    const [signinModalOpen, setSigninModalOpen] = React.useState(false);

    const doJobNumberSearch = () =>
        props.jobNumberToSearch && props.jobNumberToSearch.length > 2;

    const buildJobsQueryRequest = () => {
        return {
            states: props.stateName,
            borrowerOpt: props.searchValue,
            offeringTypeOpt: undefined,
            isFinalOpt:
                props.prelimOrFinalValue != null
                    ? props.prelimOrFinalValue === 'final'
                    : undefined,
            isCompetitiveOpt:
                props.negOrCompValue != null
                    ? props.negOrCompValue === 'competitive'
                    : undefined,
            jobOwnerEmailOpt:
                myJobsToggleValue != null &&
                props.accessToken !== '' &&
                !props.isAdmin
                    ? props.username
                    : undefined,
            startDateOpt: undefined,
            endDateOptionOpt: undefined,
            jobNumberOpt: doJobNumberSearch()
                ? props.jobNumberToSearch
                : undefined,
            pageNumber: props.page,
            resultsPerPage: props.rowsPerPage
        };
    };

    const loadJobs = queryParams => {
        let tableEndpoint = '/public/job/table';
        if (props.accessToken !== '') {
            tableEndpoint = '/job/table';
        }
        HttpHelpers.postJsonParseJson(
            constants.urlPrefix + tableEndpoint,
            queryParams,
            accessToken,
            refreshTokens
        )
            .then(result => {
                if (result.status === 200) {
                    let rowsInternal = [];
                    let jobsList = [];
                    let jobsMap = {};
                    const jobs = result.body.jobs;
                    for (let i = 0; i < jobs.length; i++) {
                        let job = jobs[i];
                        jobsList.push(job);
                        rowsInternal.push(
                            createData(
                                job._id,
                                job.jobNumber,
                                getJobType(job.offeringType).label,
                                job.state,
                                job.par,
                                job.issuer,
                                job.description,
                                job.leadManagerNames
                                    ? job.leadManagerNames
                                    : [],
                                job.municipalAdvisorNames
                                    ? job.municipalAdvisorNames
                                    : [],
                                formatDate(job.postedDate)
                            )
                        );
                    }
                    for (let j = 0; j < jobsList.length; j++) {
                        jobsMap[jobsList[j]._id] = jobsList[j];
                    }
                    setJobs(jobsMap);
                    setRows(rowsInternal);
                    setTotalRows(result.body.totalHits);
                } else if (result.status === 400) {
                    console.log('fail 400');
                }
                setIsDataLoading(false);
            })
            .catch(function (error) {
                console.log('Request failed', error);
                setIsDataLoading(false);
            });
    };

    useEffect(() => {
        props.setClearSearch(false);
        // props.setPage(0);
    }, [props.searchValue]);

    useEffect(() => {
        setIsDataLoading(true);
        let queryParams = buildJobsQueryRequest();
        loadJobs(queryParams);
    }, [
        props.page,
        props.rowsPerPage,
        myJobsToggleValue,
        props.prelimOrFinalValue,
        props.negOrCompValue,
        props.stateName,
        props.jobNumberToSearch,
        props.searchValue,
        accessToken
    ]);

    //Reset all control
    const onResetAll = () => {
        props.clearSearchAndResetPage();
        props.setStateName([]);
        props.setPrelimOrFinalValue(null);
        props.setNegOrCompValue(null);
        props.setJobNumberToSearch('');
    };

    const maybeAdminButtons = () => {
        if (!props.isAdmin) return null;
        return (
            <Box m={1}>
                <Tooltip title={'Create Job'} arrow>
                    <Fab
                        color='primary'
                        aria-label='add'
                        onClick={() => history.push('/createjob')}
                        size='small'>
                        <AddIcon />
                    </Fab>
                </Tooltip>
                <Tooltip title={'View Organizations'} arrow>
                    <Fab
                        aria-label='orgTable'
                        onClick={() => history.push('/org/table')}
                        size='small'
                        style={{ marginLeft: '10px' }}>
                        <Business />
                    </Fab>
                </Tooltip>
                <TextField
                    id='jobNumberToSearch'
                    label='Search Job #'
                    variant='outlined'
                    onChange={onJobNumberChange}
                    name='jobNumberToSearch'
                    value={props.jobNumberToSearch}
                    size={'small'}
                    style={{ marginLeft: '10px' }}
                />
            </Box>
        );
    };

    const emptyRows =
        props.rowsPerPage - Math.min(props.rowsPerPage, rows.length);

    const handleChangePage = (event, newPage) => {
        props.setPage(newPage);
    };

    const handleChangeRowsPerPage = event => {
        props.setRowsPerPage(parseInt(event.target.value, 10));
        props.setPage(0);
    };

    const onTableRowClick = row => {
        if (props.accessToken === '') {
            setSigninModalOpen(true);
        } else history.push('/view/' + row.key);
    };

    const generateType = (key, columnId) => {
        if (columnId === 'type') {
            let output = [];
            if (jobs[key]?.isFinal) {
                output.push(
                    <span
                        title='Final'
                        key={'Final'}
                        className={classes.finalTag}>
                        Final
                    </span>
                );
            } else {
                output.push(
                    <span
                        title='Prelim'
                        key={'Prelim'}
                        className={classes.prelimTag}>
                        Prelim
                    </span>
                );
            }
            if (jobs[key]?.isCompetitive) {
                output.push(
                    <span
                        title='Competitive'
                        key={'Competitive'}
                        className={classes.competitiveTag}>
                        C
                    </span>
                );
            } else if (jobs[key]?.isCompetitive === false) {
                output.push(
                    <span
                        title='Negotiated'
                        key={'Negotiated'}
                        className={classes.competitiveTag}>
                        N
                    </span>
                );
            }
            if (
                jobs[key]?.isFinal &&
                jobs[key]?.associatedFiles?.entries?.filter(function (f) {
                    return f.category === 'finalDoc';
                }).length === 0
            ) {
                output.push(
                    <span
                        title='Admin-only (finalized without final doc)'
                        key={'Admin'}
                        className={classes.adminTag}>
                        A
                    </span>
                );
            }
            if (jobs[key]?.isPrivate) {
                output.push(
                    <span
                        style={{ paddingLeft: '1px' }}
                        title='Private'
                        key={'Private'}>
                        {' '}
                        <VisibilityOffIcon
                            fontSize='small'
                            style={{ position: 'relative', top: '6px' }}
                        />
                    </span>
                );
            }
            return output;
        } else {
            return <></>;
        }
    };

    const emptyTableMessage = isDataLoading ? (
        <div>Loading...</div>
    ) : (
        <div>No results found</div>
    );

    return (
        <Container maxWidth='lg'>
            <Box className={classes.filterControl}>
                <Grid container justifyContent='space-between'>
                    <Grid item style={{ paddingRight: '10px' }}>
                        {maybeAdminButtons()}
                    </Grid>
                    <Grid item>
                        <Grid container spacing={4} alignItems='center'>
                            {props.accessToken !== '' && !props.isAdmin ? (
                                <Grid item>
                                    <MyJobs
                                        value={myJobsToggleValue}
                                        onChange={onMyJobsToggleChange}
                                    />
                                </Grid>
                            ) : null}
                            <Grid item>
                                <PreliminaryOrFinal
                                    value={props.prelimOrFinalValue}
                                    onChange={onPrelimOrFinalChange}
                                />
                            </Grid>
                            <Grid item>
                                <NegotiatedOrCompetitive
                                    value={props.negOrCompValue}
                                    onChange={onNegOrCompChange}
                                />
                            </Grid>
                            <Grid item>
                                <StateMultiSelect
                                    value={props.stateName}
                                    onChange={onStateChange}
                                />
                            </Grid>
                            <Grid item>
                                <Button variant='outlined' onClick={onResetAll}>
                                    Reset
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item></Grid>
                </Grid>
            </Box>
            <TableContainer
                component={Paper}
                className={classes.tableContainer}>
                <Table
                    stickyHeader
                    className={classes.table}
                    aria-label='offerings'>
                    <TableHead>
                        <TableRow>
                            {columns
                                .filter(
                                    column =>
                                        column.id !== 'jobNumber' ||
                                        props.isAdmin
                                )
                                .map(column => (
                                    <TableCell
                                        key={column.id}
                                        align={column.align}
                                        style={{
                                            minWidth: column.minWidth,
                                            maxWidth: column.maxWidth
                                        }}>
                                        {column.label}
                                    </TableCell>
                                ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.map(row => {
                            return (
                                <TableRow
                                    hover
                                    key={row.key}
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => onTableRowClick(row)}>
                                    {columns
                                        .filter(
                                            column =>
                                                column.id !== 'jobNumber' ||
                                                props.isAdmin
                                        )
                                        .map(column => {
                                            const value =
                                                column.id === 'type'
                                                    ? ''
                                                    : row[column.id];

                                            return (
                                                <TableCell
                                                    key={column.id}
                                                    align={column.align}
                                                    style={{
                                                        paddingTop: '10px',
                                                        paddingBottom: '10px'
                                                    }}>
                                                    {column.format
                                                        ? column.format(value)
                                                        : value}
                                                    {generateType(
                                                        row.key,
                                                        column.id
                                                    )}
                                                </TableCell>
                                            );
                                        })}
                                </TableRow>
                            );
                        })}

                        {emptyRows > 0 && totalRows > 0 && (
                            <TableRow style={{ height: 53 * emptyRows }}>
                                <TableCell colSpan={8} />
                            </TableRow>
                        )}

                        {emptyRows > 0 && totalRows === 0 && (
                            <TableRow>
                                <TableCell
                                    colSpan={8}
                                    style={{ textAlign: 'center' }}>
                                    {emptyTableMessage}
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                    <TableFooter>
                        <TableRow>
                            <TablePagination
                                rowsPerPageOptions={[10, 25, 100]}
                                count={totalRows}
                                rowsPerPage={props.rowsPerPage}
                                page={props.page}
                                SelectProps={{
                                    inputProps: {
                                        'aria-label': 'rows per page'
                                    },
                                    native: true
                                }}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                                // ActionsComponent={TablePaginationActions}
                            />
                        </TableRow>
                    </TableFooter>
                </Table>
            </TableContainer>
            <SigninModal
                open={signinModalOpen}
                onClose={() => setSigninModalOpen(false)}
                onSignin={props.onSignin}
            />
        </Container>
    );
}
