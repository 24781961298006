import * as React from 'react';
import { PropsWithChildren } from 'react';
import {
    alpha,
    createStyles,
    makeStyles,
    Theme
} from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import SearchIcon from '@material-ui/icons/Search';
import AccountCircle from '@material-ui/icons/AccountCircle';
import MoreIcon from '@material-ui/icons/MoreVert';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import Button from '@material-ui/core/Button';
// @ts-ignore
import MqLogoNew from './../assets/img/MQ_Logoandmark_RGB_KO-01.svg';
// @ts-ignore
import AdajeLogo from './../assets/img/adaje_logo_white_RGB.png';
import { Box, Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import SearchAutocomplete from './SearchAutocomplete';
import Contact from './Contact';
import clsx from 'clsx';
import TechSupport from './TechSupport';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        jumbotron: {
            backgroundColor: alpha('#fff', 0.2),
            height: '200px',
            textAlign: 'center'
        },
        spaceBelow: {
            marginBottom: 40
        },
        grow: {
            flexGrow: 1
        },
        menuButton: {
            marginRight: theme.spacing(2)
        },
        title: {
            display: 'none',
            [theme.breakpoints.up('lg')]: {
                display: 'block'
            }
        },
        search: {
            position: 'relative',
            borderRadius: theme.shape.borderRadius,
            backgroundColor: alpha(theme.palette.common.white, 0.6),
            '&:hover': {
                backgroundColor: alpha(theme.palette.common.white, 0.8)
            },
            margin: theme.spacing(2),
            marginLeft: 0,
            width: 'auto',
            [theme.breakpoints.up('lg')]: {
                marginLeft: theme.spacing(3),
                width: 'auto'
            }
        },
        searchIcon: {
            padding: theme.spacing(0, 2),
            height: '100%',
            position: 'absolute',
            pointerEvents: 'none',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
        },
        inputRoot: {
            color: 'inherit'
        },
        inputInput: {
            padding: theme.spacing(1, 1, 1, 0),
            // vertical padding + font size from searchIcon
            paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
            transition: theme.transitions.create('width'),
            width: '100%',
            [theme.breakpoints.up('lg')]: {
                width: '50ch'
            }
        },
        sectionDesktop: {
            display: 'none',
            [theme.breakpoints.up('lg')]: {
                display: 'flex'
            }
        },
        sectionMobile: {
            display: 'flex',
            [theme.breakpoints.up('lg')]: {
                display: 'none'
            }
        },
        adajeLogo: {
            paddingRight: '4em',
            width: '100%',
            height: '100%',
            maxWidth: '250px'
        },
        navBar: {
            backgroundColor: '#255026'
            // backgroundColor: '#1C224B'
        }
    })
);

interface Props extends PropsWithChildren<any> {
    isSignedIn: boolean;
    isAdmin: boolean;
    onSignOut: (history: any) => void;
    toggleSignUp: () => void;
    clearSearch: boolean;
    inputValue: string;
    setClearSearch: (boolean) => void;
    setInputValue: (string) => void;
    setSearchValue: (string) => void;
}

export default function PrimarySearchAppBar(props: Props) {
    let history = useHistory();

    const showSearchBox = window.location.pathname === '/';
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);

    // const isMenuOpen = Boolean(anchorEl);
    const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

    const handleProfileMenuOpen = event => {
        setAnchorEl(event.currentTarget);
    };

    const handleMobileMenuClose = () => {
        setMobileMoreAnchorEl(null);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
        handleMobileMenuClose();
    };

    const handleMobileMenuOpen = event => {
        setMobileMoreAnchorEl(event.currentTarget);
    };

    const signInOrOutButton = () => {
        if (props.isSignedIn)
            return (
                <Button
                    variant='text'
                    color='inherit'
                    onClick={() => props.onSignOut(history)}>
                    Sign Out
                </Button>
            );
        else
            return (
                <Button
                    variant='text'
                    color='inherit'
                    component={RouterLink}
                    to='/signin'>
                    Sign In
                </Button>
            );
    };

    const signUpButton = () => {
        if (props.isSignedIn) return null;
        else
            return (
                <Button
                    variant='text'
                    color='inherit'
                    onClick={() => history.push('/signup')}>
                    Sign Up
                </Button>
            );
    };

    const accountButton = () => {
        if (props.isSignedIn)
            return (
                <IconButton
                    edge='end'
                    aria-label='account of current user'
                    aria-controls={menuId}
                    aria-haspopup='true'
                    onClick={handleProfileMenuOpen}
                    color='inherit'>
                    <AccountCircle />
                </IconButton>
            );
    };

    const menuId = 'primary-search-account-menu';

    // const renderMenu = (
    //     <Menu
    //         anchorEl={anchorEl}
    //         anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
    //         id={menuId}
    //         keepMounted
    //         transformOrigin={{ vertical: 'top', horizontal: 'right' }}
    //         open={isMenuOpen}
    //         onClose={handleMenuClose}
    //     >
    //         <MenuItem onClick={handleMenuClose}>Profile</MenuItem>
    //         <MenuItem onClick={handleMenuClose}>My account</MenuItem>
    //     </Menu>
    // );

    const mobileMenuId = 'primary-search-account-menu-mobile';
    const renderMobileMenu = (
        <Menu
            anchorEl={mobileMoreAnchorEl}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            id={mobileMenuId}
            keepMounted
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            open={isMobileMenuOpen}
            onClose={handleMobileMenuClose}>
            <MenuItem component='a' href='/'>
                Home
            </MenuItem>
            <Contact isMobile={true} />
            <TechSupport isMobile={true} />
            <MenuItem component='a' href='/about'>
                About Us
            </MenuItem>
            <MenuItem
                component='a'
                onClick={() => {
                    if (props.isSignedIn) props.onSignOut(history);
                }}
                href={props.isSignedIn ? '' : '/signin'}>
                {props.isSignedIn ? 'Sign Out' : 'Sign In'}
            </MenuItem>
            {!props.isSignedIn && (
                <MenuItem component='a' href='/signup'>
                    Sign Up
                </MenuItem>
            )}
            {/*<MenuItem onClick={handleProfileMenuOpen}>*/}
            {/*    <IconButton*/}
            {/*        aria-label="account of current user"*/}
            {/*        aria-controls="primary-search-account-menu"*/}
            {/*        aria-haspopup="true"*/}
            {/*        color="inherit"*/}
            {/*    >*/}
            {/*        <AccountCircle />*/}
            {/*    </IconButton>*/}
            {/*    <p>Profile</p>*/}
            {/*</MenuItem>*/}
        </Menu>
    );

    return (
        <div className={clsx(classes.grow, classes.spaceBelow)}>
            <AppBar position='static' className={classes.navBar}>
                <Toolbar>
                    <Grid
                        container
                        justifyContent={'space-between'}
                        direction={'row'}
                        alignItems={'center'}>
                        <Grid item>
                            <Box style={{ padding: '10px' }}>
                                <RouterLink to={'/'}>
                                    <img
                                        src={MqLogoNew}
                                        alt={'Logo'}
                                        width={300}
                                    />
                                </RouterLink>
                            </Box>
                        </Grid>
                        <Grid item xs={2} sm={'auto'}>
                            <div className={classes.grow} />
                            <div className={classes.sectionDesktop}>
                                <Button
                                    variant='text'
                                    color='inherit'
                                    component={RouterLink}
                                    to='/'>
                                    Offerings
                                </Button>
                                <Contact isMobile={false} />
                                <TechSupport isMobile={false} />
                                <Button
                                    variant='text'
                                    color='inherit'
                                    component={RouterLink}
                                    to='/about'>
                                    About Us
                                </Button>
                                {accountButton()}
                                {signInOrOutButton()}
                                {signUpButton()}
                            </div>
                            <div className={classes.sectionMobile}>
                                <IconButton
                                    aria-label='show more'
                                    aria-controls={mobileMenuId}
                                    aria-haspopup='true'
                                    onClick={handleMobileMenuOpen}
                                    color='inherit'>
                                    <MoreIcon />
                                </IconButton>
                            </div>
                        </Grid>
                        <Grid item>
                            <Grid container alignItems={'center'}>
                                <Grid>
                                    <Box style={{ paddingRight: '10px' }}>
                                        <Typography>Powered by</Typography>
                                    </Box>
                                </Grid>
                                <Grid>
                                    <Box style={{ padding: '10px' }}>
                                        <a href='https://adajeinc.com'>
                                            <img
                                                src={AdajeLogo}
                                                alt={'Logo'}
                                                width={150}
                                            />
                                        </a>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Toolbar>
                {showSearchBox && (
                    <div className={clsx(classes.grow)}>
                        <Grid
                            container
                            justifyContent={'center'}
                            alignItems={'center'}
                            className={classes.jumbotron}>
                            <Grid item xs={12} style={{ paddingTop: '20px' }}>
                                <Typography color={'inherit'} variant={'h6'}>
                                    Friendly, uncompromising service for hosting
                                    bond offerings.
                                </Typography>
                            </Grid>
                            <Grid item xs={8}>
                                <Box>
                                    <div className={classes.search}>
                                        <div className={classes.searchIcon}>
                                            <SearchIcon />
                                        </div>
                                        <SearchAutocomplete
                                            onChange={props.setSearchValue}
                                            clearSearch={props.clearSearch}
                                            setInputValue={props.setInputValue}
                                            inputValue={props.inputValue}
                                            accessToken={props.accessToken}
                                            refreshTokensCallback={
                                                props.refreshTokensCallback
                                            }
                                        />
                                    </div>
                                </Box>
                            </Grid>
                        </Grid>
                    </div>
                )}
            </AppBar>

            {renderMobileMenu}
            {/*{renderMenu}*/}
        </div>
    );
}
