import * as React from 'react';
import { hot } from 'react-hot-loader';
import './../assets/scss/App.scss';
import { HttpHelpers } from './HttpHelpers';
import { constants } from './constants';
import '../components/types/AuthJwtProps';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import { Main } from './Main';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import withStyles from '@material-ui/core/styles/withStyles';
import { Signin } from './Signin';
import { ErrorPage } from './ErrorPage';
import { SignUp } from './SignUp';
import { ForgotPassword } from './ForgotPassword';
import Footer from './Footer';
import LoadingOverlay from 'react-loading-overlay';
import SigninModal from './SigninModal';
import Container from './About';

const theme = createTheme({
    typography: {
        h1: {
            fontSize: '3rem'
        }
    },
    palette: {
        type: 'light',
        primary: {
            main: '#255026'
            // main: "#1C224B"
        },
        secondary: {
            main: '#DAB337'
            // main: "#E06842"
        }
    }
});

const GlobalCss = withStyles({
    // @global is handled by jss-plugin-global.
    '@global': {
        // You should target [class*="MuiButton-root"] instead if you nest themes.
        '.MuiButton-root': {
            '&:hover': {
                color: theme.palette.secondary.main
            }
        }
    }
})(() => null);

interface IMyComponentState {
    credentials: any;
    authServerInfo: any;
    isWorking: boolean;
    workingMessage: string;
    signinModalOpen: boolean;
}

interface UsernameAndPassword {
    username: string;
    password: string;
}

interface SingUpRequest {
    username: string;
    password: string;
    firstName: string;
    lastName: string;
}

class App extends React.Component<Record<string, unknown>, IMyComponentState> {
    state = {
        credentials: null,
        authServerInfo: null,
        isWorking: false,
        workingMessage: '',
        signinModalOpen: false
    };
    private tokenRefreshInterval: any;
    private logoutTimeout: any;
    private events: any;

    componentDidMount() {
        document.title = 'McElwee & Quinn';
    }

    refreshTokensCallback = history => {
        const thisInstance = this;

        HttpHelpers.postJsonParseJson(
            constants.urlPrefix + '/auth/refresh',
            null,
            null,
            null
        )
            .then(function (result) {
                thisInstance.setState({
                    credentials: {
                        accessToken: result.body.accessToken,
                        username: result.body.username,
                        isAdmin: result.body.isPrinterAdmin
                    }
                });
            })
            .catch(function (error) {
                console.log('Request failed', error);
                if (history.location.pathname !== '/')
                    thisInstance.setState({
                        credentials: undefined,
                        signinModalOpen: true
                    });
            });
    };

    clearTokenRefreshTimer = () => {
        if (this.tokenRefreshInterval) {
            clearInterval(this.tokenRefreshInterval);
        }
    };

    clearTimeout = () => {
        if (this.logoutTimeout) clearTimeout(this.logoutTimeout);
    };

    setLogOutTimeout = () => {
        this.logoutTimeout = setTimeout(
            this.onSignOut,
            constants.logoutDueToInactiveTimeout
        );
    };

    resetTimeout = () => {
        this.clearTimeout();
        this.setLogOutTimeout();
    };

    destroy() {
        this.clearTimeout();
        this.clearTokenRefreshTimer();
        for (var i in this.events) {
            window.removeEventListener(this.events[i], this.resetTimeout);
        }
    }

    onSignin = (
        username: string,
        password: string,
        history: any
    ): Promise<void> => {
        let thisInstance = this;
        let credentials: UsernameAndPassword = {
            username: username,
            password: password
        };
        return HttpHelpers.postJsonUnauthenticated(
            constants.urlPrefix + '/login',
            credentials
        ).then(result => {
            if (result.status == 200) {
                result.json().then(j => {
                    thisInstance.setState({
                        credentials: {
                            accessToken: j.accessToken,
                            username: j.username,
                            isAdmin: j.isPrinterAdmin
                        }
                    });
                    if (history.location.pathname.indexOf('/signin') !== -1)
                        history.push('/');
                });
            } else throw 'Got back status code : ' + result.status;
        });
    };

    onSignOut = (history: any) => {
        let thisInstance = this;
        let logoutRequest = { accessToken: this.state.credentials.accessToken };
        HttpHelpers.postJson(
            constants.urlPrefix + '/signout',
            logoutRequest,
            logoutRequest.accessToken,
            null
        )
            .then(result => {
                thisInstance.setState({ credentials: null });
                history.push('/');
            })
            .catch(err => {
                console.log(err);
                thisInstance.setState({ credentials: null });
                history.push('/');
            });
    };

    signalAdajeWorking = (isWorking: boolean, message: string) => {
        this.setState({ isWorking: isWorking, workingMessage: message });
    };

    render(): React.ReactNode {
        const accessToken = () =>
            this.state.credentials != undefined
                ? this.state.credentials.accessToken
                : '';
        const username = () =>
            this.state.credentials != undefined
                ? this.state.credentials.username
                : '';
        const isAdmin = () =>
            this.state.credentials != undefined
                ? this.state.credentials.isAdmin
                : '';

        const mainComponent = () => {
            return (
                <LoadingOverlay
                    active={this.state.isWorking}
                    spinner
                    text={this.state.workingMessage}
                    styles={{
                        overlay: base => ({ ...base, zIndex: 10000 })
                    }}>
                    <Main
                        accessToken={accessToken()}
                        username={username()}
                        isAdmin={isAdmin()}
                        onSignin={this.onSignin}
                        onSignOut={this.onSignOut}
                        refreshTokensCallback={this.refreshTokensCallback}
                        signalAdajeWorking={this.signalAdajeWorking}
                    />
                </LoadingOverlay>
            );
        };

        return (
            <BrowserRouter>
                <div
                    className='App'
                    style={{ backgroundColor: '#F8F9FA', minHeight: '100vh' }}>
                    <ThemeProvider theme={theme}>
                        <GlobalCss />
                        <Switch>
                            <Route exact path='/signin'>
                                <Signin
                                    onSignin={this.onSignin}
                                    customStyle={{ minHeight: '75vh' }}
                                    closeModal={null}
                                />
                            </Route>
                            <Route exact path='/signin/:code'>
                                <Signin
                                    onSignin={this.onSignin}
                                    customStyle={{ minHeight: '75vh' }}
                                    closeModal={null}
                                />
                            </Route>
                            <Route exact path='/signup'>
                                <SignUp />
                            </Route>
                            <Route exact path='/forgotPassword'>
                                <ForgotPassword />
                            </Route>
                            <Route exact path='/about'>
                                {mainComponent()}
                            </Route>
                            <Route exact path='/view/:jobId'>
                                {mainComponent()}
                            </Route>
                            <Route exact path='/stats/:jobId'>
                                {mainComponent()}
                            </Route>
                            <Route exact path='/edit/:jobId'>
                                {mainComponent()}
                            </Route>
                            <Route exact path='/createjob'>
                                {mainComponent()}
                            </Route>
                            <Route exact path='/org/create'>
                                {mainComponent()}
                            </Route>
                            <Route exact path='/org/update/:orgId'>
                                {mainComponent()}
                            </Route>
                            <Route exact path='/org/table'>
                                {mainComponent()}
                            </Route>
                            <Route exact path='/redirect/:random'>
                                {mainComponent()}
                            </Route>
                            <Route exact path='/'>
                                {mainComponent()}
                            </Route>
                            <Route
                                path='*'
                                render={() => <Redirect to='/' />}
                            />
                        </Switch>
                        <Footer />
                    </ThemeProvider>
                    <SigninModal
                        open={this.state.signinModalOpen}
                        onClose={() =>
                            this.setState({ signinModalOpen: false })
                        }
                        onSignin={this.onSignin}
                    />
                </div>
            </BrowserRouter>
        );
    }
}
declare let module: Record<string, unknown>;
export default hot(module)(App);
