import * as React from 'react';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';

interface IProps {
    value: string;
    onChange: (event: any, newType: any) => void;
}

export default function NegotiatedOrCompetitive(props: IProps) {
    return (
        <ToggleButtonGroup
            value={props.value}
            exclusive
            onChange={props.onChange}
            aria-label='type'>
            <ToggleButton value='negotiated' aria-label='negotiated'>
                Negotiated
            </ToggleButton>
            <ToggleButton value='competitive' aria-label='competitive'>
                Competitive
            </ToggleButton>
        </ToggleButtonGroup>
    );
}
