import * as React from 'react';
import { useEffect, useState } from 'react';
import {
    createStyles,
    Fab,
    Link as MLink,
    Paper,
    TextField,
    Theme
} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Container from '@material-ui/core/Container';
import makeStyles from '@material-ui/core/styles/makeStyles';
import FormControl from '@material-ui/core/FormControl';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import { DocUploadCtrl } from './DocUploadCtrl';
import { constants } from './constants';
import { HttpHelpers } from './HttpHelpers';
import { Link, useHistory, useParams } from 'react-router-dom';
import MenuItem from '@material-ui/core/MenuItem';
import { Alert, AlertTitle } from '@material-ui/lab';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { isEmail, ReactMultiEmail } from 'react-multi-email';
import 'react-multi-email/style.css';
import MomentUtils from '@date-io/moment';
// @ts-ignore
import moment from 'moment';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { Job } from './JobView';
import { currencyFormatter } from './helpers/Helpers';
import CurrencyFormatInput from './CurrencyFormat';
import { RoadshowVideoPlayer } from './RoadshowVideoPlayer';
import * as H from 'history';

interface IProps {
    accessToken: string;
    isAdmin: boolean;
    refreshTokensCallback: (history: H.History<H.LocationState>) => void;
    isEdit: boolean;
    signalAdajeWorking: (boolean, string) => void;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1,
            '& > *': {
                margin: theme.spacing(1)
            }
        },
        header: {
            color: theme.palette.primary.main,
            paddingBottom: '20px'
        },
        fab: {
            '& > *': {
                margin: theme.spacing(1)
            }
        },
        formControl: {
            margin: theme.spacing(3)
        },
        formHeader: {
            marginBottom: theme.spacing(2)
        },
        topMargin: {
            marginTop: theme.spacing(1)
        }
    })
);

export interface IHaveJobIdParam {
    jobId: string;
}

export default function JobCreateEdit(props: IProps) {
    const classes = useStyles();

    let history = useHistory();

    let { jobId } = useParams<IHaveJobIdParam>();

    const refreshTokens = () => {
        props.refreshTokensCallback(history);
    };

    const [job, setJob] = React.useState(null);

    const initialFormState = {
        jobNumber: '',
        jobType: '',
        jobTitle: '',
        issuer: '',
        amount: '',
        isFinal: false,
        isCompetitive: false,
        usState: '',
        jobName: '',
        jobDescription: '',
        isPrivate: false,
        formData: new FormData(),
        fileEntries: [],
        roadshowFileEntries: [],
        postedDate: null,
        saleDate: null,
        jobOwnerEmails: [],
        files: [],
        leadManagerNames: [],
        coManagerNames: [],
        municipalAdvisorNames: [],
        counselNames: [],
        bondLinkUrl: '',
        disclaimerOverride: ''
    };

    const [allValues, setAllValues] = useState(initialFormState);

    const [managerNames, setManagerNames] = useState([]);
    const [advisorNames, setAdvisorNames] = useState([]);
    const [counselNames, setCounselNames] = useState([]);

    const [submissionState, setSubmissionState] = useState({
        status: 'pending',
        jobId: undefined
    });

    const setFileEntries = fileEntries => {
        setAllValues({ ...allValues, fileEntries: fileEntries });
    };

    const resetForm = () => {
        setAllValues(initialFormState);
        setSubmissionState({ status: 'pending', jobId: undefined });
    };

    const onChange = e => {
        setAllValues({ ...allValues, [e.target.name]: e.target.value });
    };

    const handleCheckboxChange = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        setAllValues({
            ...allValues,
            [event.target.name]: event.target.checked
        });
    };

    const onSubmit = e => {
        e.preventDefault();

        const workingMessage = () => {
            if (props.isEdit) return 'Updating job...';
            else return 'Creating job...';
        };

        props.signalAdajeWorking(true, workingMessage());

        const data = {
            jobNumber: allValues.jobNumber,
            jobType: allValues.jobType,
            jobTitle: allValues.jobTitle,
            issuer: allValues.issuer,
            amount: allValues.amount,
            jobDescription: allValues.jobDescription,
            state: allValues.usState,
            isFinal: allValues.isFinal,
            isCompetitive: allValues.isCompetitive,
            isPrivate: allValues.isPrivate,
            files: { entries: allValues.fileEntries },
            leadManagerNames: allValues.leadManagerNames,
            coManagerNames: allValues.coManagerNames,
            municipalAdvisorNames: allValues.municipalAdvisorNames,
            counselNames: allValues.counselNames,
            jobOwnerEmails: allValues.jobOwnerEmails,
            postedDate: allValues.postedDate
                ? moment(allValues.postedDate).format('MM-DD-YYYY')
                : undefined,
            saleDate: allValues.saleDate
                ? moment(allValues.saleDate).format('MM-DD-YYYY')
                : undefined,
            bondLinkUrl: allValues.bondLinkUrl
                ? allValues.bondLinkUrl
                : undefined,
            disclaimerOverride: allValues.disclaimerOverride
        };

        const url = () => {
            if (props.isEdit)
                return constants.urlPrefix + '/job/update/' + jobId;
            else return constants.urlPrefix + '/job/create';
        };

        let formData = allValues.formData;
        formData.set('props', JSON.stringify(data));

        //Why might this be failing?
        //https://stackoverflow.com/questions/49343024/getting-typeerror-failed-to-fetch-when-the-request-hasnt-actually-failed
        //https://stackoverflow.com/questions/55751394/fetch-gives-empty-response-while-waiting-for-long-period

        //https://stackoverflow.com/questions/51066238/spring-server-connection-timeout-not-working

        HttpHelpers.postFormData(
            url(),
            formData,
            props.accessToken,
            refreshTokens
        ).then(result => {
            if (result.status === 200) {
                const jobId = result.body.job._id;
                setSubmissionState({ status: 'success', jobId: jobId });
            } else {
                setSubmissionState({ status: 'fail', jobId: undefined });
            }
            props.signalAdajeWorking(false, '');
        });
    };

    const getStates = () => {
        let rv = [];
        for (let i = 0; i < constants.usStates.length; i++) {
            let usState = constants.usStates[i];
            rv.push(
                <MenuItem value={usState.abbreviation}>
                    {usState.abbreviation} ({usState.name})
                </MenuItem>
            );
        }
        return rv;
    };

    const getJobTypes = () => {
        let rv = [];
        for (let i = 0; i < constants.jobTypes.length; i++) {
            let jobType = constants.jobTypes[i];
            rv.push(
                <MenuItem key={jobType.name} value={jobType.name}>
                    {jobType.label}
                </MenuItem>
            );
        }
        return rv;
    };

    useEffect(() => {
        if (props.isEdit && job === null) {
            HttpHelpers.postJsonParseJson(
                constants.urlPrefix + '/job/details',
                { jobId: jobId },
                props.accessToken,
                refreshTokens
            )
                .then(result => {
                    if (result.status === 200) {
                        if (result.body.jobOption === undefined) {
                            alert('Job not found!'); //TODO replace with warning modal with redirect to "/"
                            history.push('/');
                        } else {
                            const jobInternal = result.body.jobOption;

                            const job: Job = {
                                key: jobInternal._id,
                                jobNumber: jobInternal.jobNumber,
                                saleDate: jobInternal.saleDate,
                                type: jobInternal.offeringType,
                                state: jobInternal.state,
                                amount: jobInternal.par,
                                title: jobInternal.title,
                                issuer: jobInternal.issuer,
                                description: jobInternal.description,
                                leadManagers: jobInternal.leadManagerNames
                                    ? jobInternal.leadManagerNames
                                    : [],
                                coManagers: jobInternal.coManagerNames
                                    ? jobInternal.coManagerNames
                                    : [],
                                municipalAdvisors:
                                    jobInternal.municipalAdvisorNames
                                        ? jobInternal.municipalAdvisorNames
                                        : [],
                                counsels: jobInternal.counselNames
                                    ? jobInternal.counselNames
                                    : [],
                                postedDate: jobInternal.postedDate,
                                files: jobInternal.associatedFiles.entries,
                                isPrivate: jobInternal.isPrivate,
                                isFinal: jobInternal.isFinal,
                                isCompetitive: jobInternal.isCompetitive,
                                jobOwnerEmails: jobInternal.jobOwnerEmails
                                    ? jobInternal.jobOwnerEmails
                                    : [],
                                bondLinkUrl: jobInternal.bondLinkUrl,
                                disclaimerOverride:
                                    jobInternal.disclaimerOverride
                            };
                            setJob(job);
                            setAllValues({
                                ...allValues,
                                jobType: job.type,
                                jobTitle: job.title,
                                jobNumber: job.jobNumber,
                                usState: job.state,
                                amount: job.amount,
                                jobDescription: job.description,
                                issuer: job.issuer,
                                postedDate: job.postedDate,
                                saleDate: job.saleDate,
                                isPrivate: job.isPrivate,
                                isFinal: job.isFinal,
                                isCompetitive: job.isCompetitive,
                                jobOwnerEmails: job.jobOwnerEmails,
                                files: job.files,
                                leadManagerNames: job.leadManagers,
                                coManagerNames: job.coManagers,
                                municipalAdvisorNames: job.municipalAdvisors,
                                counselNames: job.counsels,
                                bondLinkUrl: job.bondLinkUrl,
                                disclaimerOverride: job.disclaimerOverride
                            });
                        }
                    } else if (result.status === 400) {
                        console.log('fail 400');
                    }
                })
                .catch(function (error) {
                    console.log('Request failed', error);
                });
        }
    }, [props.isAdmin]);

    //For retrieval of available orgs
    useEffect(() => {
        HttpHelpers.postJsonParseJson(
            constants.urlPrefix + '/orgs',
            { jobId: jobId },
            props.accessToken,
            refreshTokens
        )
            .then(result => {
                if (result.status === 200) {
                    setManagerNames(result.body.managerNames);
                    setAdvisorNames(result.body.advisorNames);
                    setCounselNames(result.body.counselNames);
                }
            })
            .catch(function (error) {
                console.log('Request failed', error);
            });
    }, [props.isAdmin]);

    const growingSelectors = (
        fieldName: string,
        existingSelections: string[],
        fieldOptions: string[]
    ) => {
        return (
            <>
                {existingSelections.map((entry, index) => (
                    <FormControl
                        key={index}
                        variant={'outlined'}
                        className={classes.topMargin}
                        fullWidth>
                        <Select
                            id={fieldName + index}
                            name={fieldName + index}
                            value={existingSelections[index]}
                            onChange={e => {
                                let allOthers = existingSelections.filter(
                                    s => s !== existingSelections[index]
                                );
                                if (e.target.value === '')
                                    setAllValues({
                                        ...allValues,
                                        [fieldName]: allOthers
                                    });
                                else {
                                    //@ts-ignore
                                    existingSelections[index] = e.target.value;
                                    setAllValues({
                                        ...allValues,
                                        [fieldName]: existingSelections
                                    });
                                }
                            }}>
                            <MenuItem value={''}>
                                <em>None</em>
                            </MenuItem>
                            {fieldOptions.map(option => (
                                <MenuItem key={option} value={option}>
                                    {option}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                ))}
                <FormControl
                    variant={'outlined'}
                    className={classes.topMargin}
                    fullWidth>
                    <Select
                        id={fieldName + existingSelections.length}
                        name={fieldName + existingSelections.length}
                        value={''}
                        onChange={e => {
                            if (e.target.value !== '')
                                setAllValues({
                                    ...allValues,
                                    [fieldName]: [
                                        ...existingSelections,
                                        e.target.value
                                    ]
                                });
                        }}>
                        <MenuItem value={''}>
                            <em>None</em>
                        </MenuItem>
                        {fieldOptions.map(option => (
                            <MenuItem key={option} value={option}>
                                {option}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </>
        );
    };

    const backToView = () => {
        if (!props.isAdmin) return null;
        return (
            <Fab
                aria-label='back'
                onClick={() => {
                    if (props.isEdit) history.push('/view/' + jobId);
                    else history.push('/');
                }}
                size={'small'}>
                <ArrowBackIcon />
            </Fab>
        );
    };

    if (!props.isAdmin) {
        return <div>Log in as admin to view.</div>;
    } else if (submissionState.status === 'success') {
        return (
            <Alert severity='success'>
                <AlertTitle>
                    {props.isEdit ? 'Job Updated' : 'Job Created'}
                </AlertTitle>
                <ul>
                    {props.isEdit ? (
                        <li>
                            <Link to={'/view/' + submissionState.jobId}>
                                View updated job
                            </Link>
                        </li>
                    ) : (
                        <>
                            <li>
                                <Link to={'/view/' + submissionState.jobId}>
                                    View created job
                                </Link>
                            </li>
                            <li>
                                <MLink
                                    href='#'
                                    onClick={e => {
                                        e.preventDefault();
                                        resetForm();
                                    }}>
                                    Create another job
                                </MLink>
                            </li>
                        </>
                    )}
                    <li>
                        <Link to='/'>Return home</Link>
                    </li>
                </ul>
            </Alert>
        );
    } else {
        return (
            <Container maxWidth='md'>
                <div className={classes.fab}>{backToView()}</div>
                <Paper elevation={2}>
                    <form onSubmit={onSubmit}>
                        <FormControl
                            component='fieldset'
                            className={classes.formControl}>
                            <Typography
                                variant='h4'
                                color='primary'
                                align='center'
                                className={classes.formHeader}>
                                {props.isEdit ? 'Edit Job' : 'Create Job'}
                            </Typography>

                            <Grid container spacing={3}>
                                <Grid item xs={6}>
                                    <TextField
                                        id='jobNumber'
                                        label='Job Number'
                                        variant='outlined'
                                        onChange={onChange}
                                        name='jobNumber'
                                        value={allValues.jobNumber}
                                        fullWidth
                                        required
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField
                                        id='issuer'
                                        label='Issuer'
                                        variant='outlined'
                                        onChange={onChange}
                                        name='issuer'
                                        value={allValues.issuer}
                                        fullWidth
                                        required
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <FormControl variant='outlined' fullWidth>
                                        <InputLabel id='jobType-label'>
                                            Job Type *
                                        </InputLabel>
                                        <Select
                                            labelId='jobType-label'
                                            id='jobType-select'
                                            name='jobType'
                                            value={allValues.jobType}
                                            onChange={onChange}
                                            label='Job Type'
                                            required>
                                            <MenuItem value=''>
                                                <em>None</em>
                                            </MenuItem>
                                            {getJobTypes()}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField
                                        label='Amount'
                                        variant='outlined'
                                        value={allValues.amount}
                                        onChange={onChange}
                                        name='amount'
                                        InputProps={{
                                            inputComponent: CurrencyFormatInput
                                        }}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <FormControl variant='outlined' fullWidth>
                                        <InputLabel id='usState-label'>
                                            State *
                                        </InputLabel>
                                        <Select
                                            labelId='usState-label'
                                            id='usState-select'
                                            name='usState'
                                            value={allValues.usState}
                                            onChange={onChange}
                                            label='State'
                                            required>
                                            <MenuItem value=''>
                                                <em>None</em>
                                            </MenuItem>
                                            {getStates()}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField
                                        fullWidth
                                        id='jobTitle'
                                        name={'jobTitle'}
                                        value={allValues.jobTitle}
                                        label='Job Title'
                                        variant='outlined'
                                        onChange={onChange}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        multiline
                                        fullWidth
                                        name={'jobDescription'}
                                        value={allValues.jobDescription}
                                        id='jobDescription'
                                        rows={5}
                                        label='Job Description'
                                        variant='outlined'
                                        required
                                        onChange={onChange}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField
                                        fullWidth
                                        id='bondLinkUrl'
                                        variant='outlined'
                                        name={'bondLinkUrl'}
                                        value={allValues.bondLinkUrl}
                                        label='BondLink URL'
                                        onChange={onChange}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        multiline
                                        fullWidth
                                        name={'disclaimerOverride'}
                                        value={allValues.disclaimerOverride}
                                        id='disclaimerOverride'
                                        rows={5}
                                        label='Disclaimer Override'
                                        variant='outlined'
                                        onChange={onChange}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography
                                        variant={'h6'}
                                        color={'primary'}>
                                        Lead Managers
                                    </Typography>
                                    {growingSelectors(
                                        'leadManagerNames',
                                        allValues.leadManagerNames,
                                        managerNames
                                    )}
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography
                                        variant={'h6'}
                                        color={'primary'}>
                                        Co-Managers
                                    </Typography>
                                    {growingSelectors(
                                        'coManagerNames',
                                        allValues.coManagerNames,
                                        managerNames
                                    )}
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography
                                        variant={'h6'}
                                        color={'primary'}>
                                        Municipal Advisors
                                    </Typography>
                                    {growingSelectors(
                                        'municipalAdvisorNames',
                                        allValues.municipalAdvisorNames,
                                        advisorNames
                                    )}
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography
                                        variant={'h6'}
                                        color={'primary'}>
                                        Counsels
                                    </Typography>
                                    {growingSelectors(
                                        'counselNames',
                                        allValues.counselNames,
                                        counselNames
                                    )}
                                </Grid>
                            </Grid>
                            <DocUploadCtrl
                                formData={allValues.formData}
                                existingFiles={allValues.files}
                                fileEntries={allValues.fileEntries}
                                setFileEntries={setFileEntries}
                                docCount={allValues.files.length}
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={allValues.isFinal}
                                        onChange={handleCheckboxChange}
                                        name='isFinal'
                                    />
                                }
                                label='Final'
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={allValues.isPrivate}
                                        onChange={handleCheckboxChange}
                                        name='isPrivate'
                                    />
                                }
                                label='Private'
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={allValues.isCompetitive}
                                        onChange={handleCheckboxChange}
                                        name='isCompetitive'
                                    />
                                }
                                label='Competitive'
                            />
                            <Grid container spacing={3}>
                                <Grid item xs={6}>
                                    <ReactMultiEmail
                                        placeholder='Job owner emails'
                                        emails={allValues.jobOwnerEmails}
                                        onChange={_emails => {
                                            setAllValues({
                                                ...allValues,
                                                jobOwnerEmails: _emails
                                            });
                                        }}
                                        validateEmail={email => {
                                            return isEmail(email); // return boolean
                                        }}
                                        getLabel={(
                                            email,
                                            index,
                                            removeEmail
                                        ) => {
                                            return (
                                                <div data-tag key={index}>
                                                    {email}
                                                    <span
                                                        data-tag-handle
                                                        style={{
                                                            cursor: 'pointer'
                                                        }}
                                                        onClick={() =>
                                                            removeEmail(index)
                                                        }>
                                                        ×
                                                    </span>
                                                </div>
                                            );
                                        }}
                                    />
                                </Grid>
                            </Grid>
                            <Grid
                                container
                                spacing={3}
                                className={classes.topMargin}>
                                <Grid item xs={6}>
                                    <MuiPickersUtilsProvider
                                        utils={MomentUtils}>
                                        <DatePicker
                                            label='Posted Date'
                                            value={allValues.postedDate}
                                            format='MM/DD/yyyy'
                                            onChange={newValue => {
                                                setAllValues({
                                                    ...allValues,
                                                    postedDate: newValue
                                                });
                                            }}
                                            // @ts-ignore
                                            renderInput={params => (
                                                <TextField {...params} />
                                            )}
                                        />
                                    </MuiPickersUtilsProvider>
                                </Grid>
                                <Grid item xs={6}>
                                    <MuiPickersUtilsProvider
                                        utils={MomentUtils}>
                                        <DatePicker
                                            label='Sale Date'
                                            value={allValues.saleDate}
                                            format='MM/DD/yyyy'
                                            onChange={newValue => {
                                                setAllValues({
                                                    ...allValues,
                                                    saleDate: newValue
                                                });
                                            }}
                                            // @ts-ignore
                                            renderInput={params => (
                                                <TextField {...params} />
                                            )}
                                        />
                                    </MuiPickersUtilsProvider>
                                </Grid>
                            </Grid>
                        </FormControl>
                        <Button
                            variant='contained'
                            color='primary'
                            type='submit'
                            fullWidth>
                            Submit
                        </Button>
                    </form>
                </Paper>
                {submissionState.status === 'fail' ? (
                    <Alert severity='error'>
                        Failed to {props.isEdit ? 'edit' : 'create'} job.
                    </Alert>
                ) : null}
            </Container>
        );
    }
}
