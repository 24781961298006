import React, { useCallback, useState } from 'react';
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Fab,
    Slider,
    Theme,
    Typography,
    makeStyles
} from '@material-ui/core';
import Cropper, { Area, Size } from 'react-easy-crop';
import { Image as ImageIcon } from '@material-ui/icons';
import DeleteButton from './DeleteButton';
import getCroppedImg from './cropImage';

const useStyles = makeStyles((theme: Theme) => ({
    uploadButton: {
        color: theme.palette.primary.light
    },
    logoPreview: {
        maxWidth: '200px',
        maxHeight: '60px'
    },
    input: {
        display: 'none'
    }
}));

interface ILogoUploadControlProps {
    logoUrl: string;
    setLogoUrl: (url: string) => void;
    setLogo: (file: File) => void;
    removeLogo: () => void;
}

const DEFAULT_CROP = { x: 0, y: 0 };
const DEFAULT_CROP_SIZE = {
    width: 400,
    height: 120
};
const CROP_CONTAINER_HEIGHT = 500;

const LogoUploadCtrl: React.FC<ILogoUploadControlProps> = ({
    logoUrl,
    setLogoUrl,
    setLogo,
    removeLogo
}: ILogoUploadControlProps) => {
    const classes = useStyles();
    const [cropModalOpen, setCropModalOpen] = useState(false);
    const [crop, setCrop] = useState(DEFAULT_CROP);
    const [scale, setScale] = useState(1);
    const [previewImageWidth, setPreviewImageWidth] = useState(0);
    const [previewImageHeight, setPreviewImageHeight] = useState(0);
    const [cropAreaPixels, setCropAreaPixels] = useState<Area>();
    const [cropSize, setCropSize] = useState<Size>(DEFAULT_CROP_SIZE);

    const handleImageUpload = ({
        target
    }: React.ChangeEvent<HTMLInputElement>) => {
        const file = target.files[0];
        if (file.size > 5e7) {
            alert('File size exceeds limit of 50MB.');
        } else {
            const logoUrl = URL.createObjectURL(file);
            setLogoUrl(logoUrl);
            setCropModalOpen(true);
        }
    };

    const closeModal = () => {
        setCropModalOpen(false);
        setCropAreaPixels(undefined);
        setScale(1);
        setCrop(DEFAULT_CROP);
        setCropSize(DEFAULT_CROP_SIZE);
    };

    const onSubmit = useCallback(async () => {
        const [blob, croppedImageUrl] = await getCroppedImg(
            logoUrl,
            cropAreaPixels
        );
        setLogo(blob);
        setLogoUrl(croppedImageUrl);
        closeModal();
    }, [cropAreaPixels]);

    const onCropComplete = useCallback(
        (croppedArea, croppedAreaPixels) =>
            setCropAreaPixels(croppedAreaPixels),
        []
    );

    return !logoUrl ? (
        <Box m={1} my={2}>
            <Fab
                className={classes.uploadButton}
                aria-label='upload file'
                component={'label'}
                variant={'extended'}>
                <input
                    accept='image/*'
                    className={classes.input}
                    type='file'
                    hidden
                    onChange={handleImageUpload}
                />
                <ImageIcon fontSize='large' />
                Upload Logo
            </Fab>
        </Box>
    ) : (
        <>
            <Box m={1} my={2}>
                <DeleteButton
                    onClick={() => {
                        removeLogo();
                        setScale(1);
                        setCrop(DEFAULT_CROP);
                    }}
                    label={'Remove Logo'}
                />
                <Box mt={2}>
                    <label id='preview-image'>Preview:</label>
                </Box>
                <hr />
                <Box mt={2}>
                    <Box
                        border='1px dashed gray'
                        height={previewImageHeight}
                        width={previewImageWidth}
                        mb={2}>
                        <img
                            src={logoUrl}
                            className={classes.logoPreview}
                            onLoad={e => {
                                setPreviewImageWidth(e.currentTarget.width);
                                setPreviewImageHeight(e.currentTarget.height);
                            }}
                        />
                    </Box>
                </Box>
            </Box>
            <Dialog
                open={cropModalOpen}
                onClose={closeModal}
                PaperProps={{
                    style: {
                        height: CROP_CONTAINER_HEIGHT
                    }
                }}
                maxWidth='sm'
                fullWidth>
                <DialogTitle>
                    <Typography variant='h6' color='primary'>
                        Crop Organization Image
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    <Box my={1}>
                        <Typography id='scale-slider' gutterBottom>
                            Scale
                        </Typography>
                        <Slider
                            aria-labelledby='scale-slider'
                            value={scale}
                            onChange={(e, v: number) => setScale(v)}
                            min={0.01}
                            max={4}
                            step={0.001}
                        />
                    </Box>
                    <Box my={1}>
                        <Typography id='scale-slider' gutterBottom>
                            Crop Width
                        </Typography>
                        <Slider
                            aria-labelledby='scale-slider'
                            value={cropSize.width}
                            onChange={(e, v: number) =>
                                setCropSize(cs => ({
                                    ...cs,
                                    width: v
                                }))
                            }
                            min={0}
                            max={400}
                            step={0.001}
                        />
                    </Box>
                    <Box my={1}>
                        <Typography id='scale-slider' gutterBottom>
                            Crop Height
                        </Typography>
                        <Slider
                            aria-labelledby='scale-slider'
                            value={cropSize.height}
                            onChange={(e, v: number) =>
                                setCropSize(cs => ({
                                    ...cs,
                                    height: v
                                }))
                            }
                            min={0}
                            max={120}
                            step={0.001}
                        />
                    </Box>
                    <Box
                        style={{
                            height: '150px',
                            width: 'auto',
                            position: 'relative',
                            background: 'white'
                        }}>
                        <Cropper
                            image={logoUrl}
                            crop={crop}
                            zoom={scale}
                            onCropChange={setCrop}
                            onCropComplete={onCropComplete}
                            onZoomChange={setScale}
                            minZoom={0.01}
                            cropSize={cropSize}
                            onCropSizeChange={setCropSize}
                            restrictPosition={false}
                        />
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button color='secondary' onClick={closeModal}>
                        Use Original Image
                    </Button>
                    <Button color='primary' onClick={onSubmit}>
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};
export default LogoUploadCtrl;
