import * as React from 'react';
import { HttpHelpers } from '../HttpHelpers';
import { useRef } from 'react';
import ReactPlayer from 'react-player/lazy';
import { List, ListItem, ListItemText } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { Job } from '../JobView';

interface IProps {
    job: Job;
    selectedRoadshowIdx: number;
}

const RoadshowVideoPlayer = ({job, selectedRoadshowIdx}: IProps) => {
    const playerRef = useRef(null);

    //disable watch ahead:
    //https://stackoverflow.com/questions/11903545/how-to-disable-seeking-with-html5-video-tag

    //Cant fully disable download
    //https://stackoverflow.com/questions/9756837/prevent-html5-video-from-being-downloaded-right-click-saved/9756909#9756909

    const buildListItem = item => {
        let str = item.title + ' ' + item.timestampString;
        return (
            <ListItem
                button={true}
                onClick={() => {
                    playerRef.current.seekTo(item.timestampSeconds);
                }}>
                <ListItemText primary={str} />
            </ListItem>
        );
    };

    const maybeTimestampList = () => {
        if (job) {
            return (
                <List>
                    {job.roadshowMetas[selectedRoadshowIdx].timestamps.map((item, i) => {
                        return buildListItem(item);
                    })}
                </List>
            );
        }
    };

    return (
        <>
            <Grid container>
                <Grid item xs={3}>
                    {maybeTimestampList()}
                </Grid>
                <Grid item xs={7}>
                    <ReactPlayer
                        ref={playerRef}
                        url={HttpHelpers.buildUrl(
                            '/api/video/' +
                                job.key +
                                '/' +
                                job.roadshowMetas[selectedRoadshowIdx].videoFileName
                        )}
                        config={{
                            file: { attributes: { controlsList: 'nodownload' } }
                        }}
                        onContextMenu={e => e.preventDefault()}
                        className='react-player'
                        controls
                        width='100%'
                        height='100%'
                    />
                </Grid>
            </Grid>
        </>
    );
};

export { RoadshowVideoPlayer };
