import * as React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Container, createStyles, Theme } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
// @ts-ignore
import AdajePunchoutLogo from './../assets/img/adaje_logo_footer.svg';
// @ts-ignore
import AdajeLogo from './../assets/img/adaje_logo.svg';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        footer: {
            display: 'flex',
            minHeight: '100vh',
            flexDirection: 'column'
        }
    })
);

export default function Footer() {
    const classes = useStyles();

    return (
        <Box
            color={'#151A3A'}
            px={{ xs: 3, sm: 10 }}
            pt={{ xs: 5, sm: 3 }}
            pb={{ xs: 5, sm: 10 }}
            mt={{ xs: 2, sm: 4 }}>
            <Container maxWidth={'lg'}>
                {/*<Grid container*/}
                {/*      direction="row"*/}
                {/*      justifyContent="center"*/}
                {/*      alignItems="center"*/}
                {/*>*/}
                {/*    <Grid item>*/}
                {/*        <Typography component="div">*/}
                {/*            <Box p={2} fontSize={12}>*/}
                {/*                Powered by*/}
                {/*            </Box>*/}
                {/*        </Typography>*/}
                {/*    </Grid>*/}
                {/*    <Grid item>*/}
                {/*        <a href="https://adajeinc.com">*/}
                {/*            <img src={AdajeLogo} alt={'Logo'} width="100px" />*/}
                {/*        </a>*/}
                {/*    </Grid>*/}
                {/*</Grid>*/}
                <Box
                    textAlign={'center'}
                    pt={{ xs: 1, sm: 2 }}
                    pb={{ xs: 5, sm: 5 }}>
                    Copyright &reg; {new Date().getFullYear()}
                </Box>
            </Container>
        </Box>
    );
}
