import * as React from 'react';
import {
    Container,
    createStyles,
    makeStyles,
    useTheme,
    Paper,
    Theme
} from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { Data } from './PaginatedTable';
import { useEffect, useRef } from 'react';
import { HttpHelpers } from './HttpHelpers';
import { constants } from './constants';
import { useParams } from 'react-router-dom';

import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import ListItemText from '@material-ui/core/ListItemText';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
import Chip from '@material-ui/core/Chip';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        formControl: {
            margin: theme.spacing(1),
            minWidth: 120,
            maxWidth: 300
        }
    })
);

//TODO width needs to be dynamic to be like munios
const MenuProps = {
    anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'left'
    },
    transformOrigin: {
        vertical: 'top',
        horizontal: 'left'
    },
    getContentAnchorEl: null,
    PaperProps: {
        style: {
            maxHeight: 325,
            width: 325,
            marginTop: '10px'
        }
    }
};

interface IProps {
    value: string[];
    onChange: (event: any) => void;
}

export default function StateMultiSelect(props: IProps) {
    const classes = useStyles();

    return (
        <FormControl className={classes.formControl}>
            <InputLabel id='demo-mutiple-checkbox-label'>State(s)</InputLabel>
            <Select
                labelId='state-select'
                id='state-selection'
                multiple
                value={props.value}
                onChange={props.onChange}
                input={<Input />}
                renderValue={(selected: string[]) => selected.join(', ')}
                MenuProps={MenuProps}>
                {constants.usStates.map(entry => (
                    <MenuItem
                        style={{ paddingTop: '0px', paddingBottom: '0px' }}
                        key={entry.abbreviation}
                        value={entry.abbreviation}>
                        <Checkbox
                            checked={
                                props.value.indexOf(entry.abbreviation) > -1
                            }
                        />
                        <ListItemText
                            primary={entry.name
                                .trim()
                                .toLowerCase()
                                .replace(/\w\S*/g, w =>
                                    w.replace(/^\w/, c => c.toUpperCase())
                                )}
                        />
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
}
