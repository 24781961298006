import * as React from 'react';
import {useEffect, useState} from 'react';
import {
    Box,
    Container,
    Grid,
    InputAdornment,
    InputLabel,
    OutlinedInput,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableFooter,
    TableHead,
    TablePagination,
    TableRow,
    Tooltip,
    Typography
} from '@material-ui/core';
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import Paper from '@material-ui/core/Paper';
import {useHistory} from 'react-router-dom';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import {HttpHelpers} from './HttpHelpers';
import {constants} from './constants';
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";
import FormControl from "@material-ui/core/FormControl";
import clsx from "clsx";

const useStyles = makeStyles((theme: Theme) => createStyles({
    tableContainer: {
        maxWidth: 1500
    },
    table: {
        minWidth: 500
    },
    margin: {
        marginLeft: theme.spacing(1),
    },
    textField: {
        width: '25ch',
    },
}));

interface Column {
    id: 'logo' | 'name' | 'type' | 'url';
    label: string;
    minWidth?: number;
    maxWidth?: number;
    align?: 'center';
    format?: (value: number) => string;
}

const columns: Column[] = [
    { id: 'logo', label: 'Logo' },
    { id: 'name', label: 'Name' },
    { id: 'type', label: 'Type' },
    { id: 'url', label: 'Website' }
];

interface Row {
    id: string;
    logo: any;
    name: string;
    type: string;
    url: string;
}

interface IProps {
    refreshTokensCallback: (history: History) => void;
    isAdmin: boolean;
    accessToken: string;
}

export default function OrganizationTable(props: IProps) {
    const history = useHistory();

    const refreshTokens = () => {
        props.refreshTokensCallback(history);
    };

    const classes = useStyles();

    const [rows, setRows] = useState([]);
    const [totalRows, setTotalRows] = useState(0);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [isDataLoading, setIsDataLoading] = useState(false);
    const [orgNameToSearch, setOrgNameToSearch] = useState('')

    const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length);

    const emptyTableMessage = isDataLoading ? (
        <div>Loading...</div>
    ) : (
        <div>No results found</div>
    );

    const handleChangeRowsPerPage = event => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    useEffect(() => {
        setIsDataLoading(true);
        console.log("org name to search:", orgNameToSearch);
        HttpHelpers.postJsonParseJson(
            constants.urlPrefix + '/org/table',
            {
                pageNumber: page,
                resultsPerPage: rowsPerPage,
                orgNameToSearch: orgNameToSearch
            },
            props.accessToken,
            refreshTokens
        )
            .then(result => {
                if (result.status === 200) {
                    const orgsAndLogos = result.body.orgsAndLogos;
                    const rowsInternal: Row[] = [];
                    for (let i = 0; i < orgsAndLogos.length; i++) {
                        let orgAndLogo = orgsAndLogos[i];
                        let org = orgAndLogo.org;
                        let logo = orgAndLogo.logoOption;
                        rowsInternal.push({
                            id: org._id,
                            logo: logo,
                            name: org.name,
                            type: org.organizationType,
                            url: org.url
                        });
                    }
                    setRows(rowsInternal);
                    setTotalRows(result.body.totalHits);
                }
            })
            .catch(function (error) {
                console.log('Request failed', error);
            })
            .finally(() => {
                console.log('setting data loading to false');
                setIsDataLoading(false);
            });
    }, [props.isAdmin, props.accessToken, page, rowsPerPage, orgNameToSearch]);

    const onTableRowClick = (row: Row) => {
        history.push('/org/update/' + row.id);
    };

    const getCell = (row: Row, column: Column) => {
        let cellValue = row[column.id];

        if (column.id === 'logo' && cellValue) {
            return (
                <img src={cellValue} alt={'Logo'} width='60px' height='100%' />
            );
        } else return cellValue;
    };

    const getCells = (row: Row) => {
        return columns.map((column: Column) => (
            <TableCell
                key={column.id}
                align={column.align}
                style={{ paddingTop: '10px', paddingBottom: '10px' }}>
                {getCell(row, column)}
            </TableCell>
        ));
    };

    if (!props.isAdmin) return <div>Log in as admin to view.</div>;
    else
        return (
            <Container maxWidth={'lg'}>
                <Box pb={2}>
                    <Grid container justifyContent='space-between'>
                        <Grid item>
                            <Box mx={1} minWidth={'120px'}>
                                <Fab
                                    aria-label='back'
                                    onClick={() => {
                                        history.push('/');
                                    }}
                                    size={'small'}>
                                    <ArrowBackIcon />
                                </Fab>
                                <Tooltip title={'Create Org'} arrow>
                                    <Fab
                                        color='primary'
                                        aria-label='createOrg'
                                        onClick={() =>
                                            history.push('/org/create')
                                        }
                                        size='small'
                                        style={{ marginLeft: '10px' }}>
                                        <AddIcon />
                                    </Fab>
                                </Tooltip>
                                <FormControl className={clsx(classes.margin, classes.textField)}
                                             variant="outlined" size='small'>
                                    <InputLabel htmlFor="org-search">Search</InputLabel>
                                    <OutlinedInput
                                        id="org-search"
                                        type={'text'}
                                        value={orgNameToSearch}
                                        onChange={e => setOrgNameToSearch(e.target.value)}
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="clear search"
                                                    onClick={e => setOrgNameToSearch('')}
                                                    edge="end"
                                                >
                                                    <CloseIcon/>
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                        labelWidth={70}
                                    />
                                </FormControl>
                            </Box>
                        </Grid>
                        <Grid item>
                            <Typography
                                variant='h3'
                                color='primary'
                                align='left'>
                                Organizations
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Box minWidth={'314px'}></Box>
                        </Grid>
                    </Grid>
                </Box>
                <TableContainer
                    component={Paper}
                    className={classes.tableContainer}>
                    <Table
                        stickyHeader
                        className={classes.table}
                        aria-label='offerings'>
                        <TableHead>
                            <TableRow>
                                {columns.map((column: Column) => (
                                    <TableCell
                                        key={column.id}
                                        align={column.align}
                                        style={{
                                            minWidth: column.minWidth,
                                            maxWidth: column.maxWidth
                                        }}>
                                        {column.label}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rows.map((row: Row) => (
                                <TableRow
                                    hover
                                    key={row.name}
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => onTableRowClick(row)}>
                                    {getCells(row)}
                                </TableRow>
                            ))}

                            {emptyRows > 0 && totalRows > 0 && (
                                <TableRow style={{ height: 53 * emptyRows }}>
                                    <TableCell colSpan={8} />
                                </TableRow>
                            )}

                            {emptyRows > 0 && totalRows === 0 && (
                                <TableRow>
                                    <TableCell
                                        colSpan={8}
                                        style={{ textAlign: 'center' }}>
                                        {emptyTableMessage}
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                        <TableFooter>
                            <TableRow>
                                <TablePagination
                                    rowsPerPageOptions={[10, 25, 100]}
                                    count={totalRows}
                                    page={page}
                                    onPageChange={(event, newPage) =>
                                        setPage(newPage)
                                    }
                                    rowsPerPage={rowsPerPage}
                                    onRowsPerPageChange={
                                        handleChangeRowsPerPage
                                    }
                                />
                            </TableRow>
                        </TableFooter>
                    </Table>
                </TableContainer>
            </Container>
        );
}
