import * as React from 'react';
import {
    createStyles,
    Theme,
    withStyles,
    WithStyles
} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import { MenuItem } from '@material-ui/core';

const styles = (theme: Theme) =>
    createStyles({
        root: {
            margin: 0,
            padding: theme.spacing(2)
        },
        closeButton: {
            position: 'absolute',
            right: theme.spacing(1),
            top: theme.spacing(1),
            color: theme.palette.grey[500]
        }
    });

export interface DialogTitleProps extends WithStyles<typeof styles> {
    id: string;
    children: React.ReactNode;
    onClose: () => void;
}

const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant='h5' color={'primary'}>
                {children}
            </Typography>
            {onClose ? (
                <IconButton
                    aria-label='close'
                    className={classes.closeButton}
                    onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles((theme: Theme) => ({
    root: {
        padding: theme.spacing(2)
    }
}))(MuiDialogContent);

const DialogActions = withStyles((theme: Theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1)
    }
}))(MuiDialogActions);

export interface IProps {
    isMobile: boolean;
}

export default function TechSupport(props: IProps) {
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    return (
        <>
            {props.isMobile ? (
                <MenuItem component='a' onClick={handleClickOpen}>
                    Tech Support
                </MenuItem>
            ) : (
                <Button
                    variant='text'
                    color='inherit'
                    onClick={handleClickOpen}>
                    Tech Support
                </Button>
            )}
            <Dialog
                onClose={handleClose}
                aria-labelledby='customized-dialog-title'
                open={open}>
                <DialogTitle id='customized-dialog-title' onClose={handleClose}>
                    Tech Support
                </DialogTitle>
                <DialogContent dividers>
                    <Typography variant={'subtitle1'} color={'textPrimary'}>
                        Need website, technical, or roadshow support? Please
                        contact Adaje:
                    </Typography>
                    <br />
                    <Typography color={'textPrimary'}>
                        <a href={'mailto:support@adajeinc.com'}>
                            support@adajeinc.com
                        </a>
                        <br />
                    </Typography>
                </DialogContent>
            </Dialog>
        </>
    );
}
