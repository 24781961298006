import * as React from 'react';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';

interface IProps {
    value: string;
    onChange: (event: any, newStatus: any) => void;
}

export default function PreliminaryOrFinal(props: IProps) {
    return (
        <ToggleButtonGroup
            value={props.value}
            exclusive
            onChange={props.onChange}
            aria-label='status'>
            <ToggleButton value='preliminary' aria-label='preliminary'>
                Preliminary
            </ToggleButton>
            <ToggleButton value='final' aria-label='final'>
                Final
            </ToggleButton>
        </ToggleButtonGroup>
    );
}
