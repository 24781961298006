import * as React from 'react';
import { useEffect } from 'react';
import {
    Box,
    Container,
    createStyles,
    Fab,
    Tab,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Theme
} from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { HttpHelpers } from './HttpHelpers';
import { constants } from './constants';
import { useHistory, useParams } from 'react-router-dom';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import withStyles from '@material-ui/core/styles/withStyles';
import { toTitleCase } from './helpers/Helpers';
//@ts-ignore
import moment from 'moment';
import { Job } from './JobView';
import { TabContext, TabList, TabPanel } from '@material-ui/lab';
import MaterialTable from 'material-table';

interface IProps {
    accessToken: string;
    isAdmin: boolean;
    refreshTokensCallback: (history: History) => void;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1
        },
        paper: {
            padding: theme.spacing(2),
            textAlign: 'left',
            color: theme.palette.text.secondary
        },
        header: {
            color: theme.palette.primary.main,
            paddingBottom: '20px'
        },
        fab: {
            '& > *': {
                margin: theme.spacing(1)
            }
        },
        button: {
            margin: theme.spacing(1)
        }
    })
);

const DeleteButton = withStyles({
    root: {
        background: 'red',
        color: 'white',
        '&:hover': {
            backgroundColor: 'darkRed'
        }
    },
    label: {
        textTransform: 'capitalize'
    }
})(Fab);

export default function JobStats(props: IProps) {
    const classes = useStyles();

    let history = useHistory();

    let { jobId } = useParams();

    const refreshTokens = () => {
        props.refreshTokensCallback(history);
    };

    const [job, setJob] = React.useState(null);

    const [stats, setStats] = React.useState(null);

    const [value, setValue] = React.useState('views');

    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        setValue(newValue);
    };

    useEffect(() => {
        HttpHelpers.postJsonParseJson(
            constants.urlPrefix + '/job/details',
            { jobId: jobId },
            props.accessToken,
            refreshTokens
        )
            .then(result => {
                if (result.status === 200) {
                    if (result.body.jobOption === undefined) {
                        alert('Job not found!'); //TODO replace with warning modal with redirect to "/"
                        history.push('/');
                    } else {
                        const jobInternal = result.body.jobOption;
                        const jobType = constants.jobTypes.find(e => {
                            return e.name === jobInternal.offeringType;
                        });
                        const job: Job = {
                            key: jobInternal._id,
                            type: jobType.label,
                            state: jobInternal.state,
                            amount: jobInternal.par,
                            title: jobInternal.title,
                            issuer: toTitleCase(jobInternal.issuer),
                            description: jobInternal.description,
                            leadManagers: [],
                            coManagers: [],
                            counsels: [],
                            municipalAdvisors: [],
                            postedDate: jobInternal.postedDate,
                            files: jobInternal.associatedFiles.entries,
                            jobNumber: jobInternal.jobNumber,
                            saleDate: jobInternal.saleDate,
                            isFinal: jobInternal.isFinal,
                            isCompetitive: jobInternal.isCompetitive,
                            isPrivate: jobInternal.isPrivate,
                            jobOwnerEmails: jobInternal.jobOwnerEmails,
                            disclaimerOverride: jobInternal.disclaimerOverride,
                            bondLinkUrl: jobInternal.bondLinkUrl
                        };
                        setJob(job);
                    }
                } else if (result.status === 400) {
                    console.log('fail 400');
                }
            })
            .catch(function (error) {
                console.log('Request failed', error);
            });

        HttpHelpers.getJson(
            constants.urlPrefix + '/job/events/' + jobId,
            true,
            props.accessToken,
            refreshTokens
        )
            .then(result => {
                if (result.status === 200) {
                    console.log(result.body);
                    setStats(result.body);
                } else if (result.status === 400) {
                    console.log('fail 400');
                }
            })
            .catch(function (error) {
                console.log('Request failed', error);
            });
    }, [props.isAdmin]);

    const backToView = () => {
        return (
            <Fab
                aria-label='back'
                onClick={() => history.push('/view/' + jobId)}
                size={'small'}>
                <ArrowBackIcon />
            </Fab>
        );
    };

    const tabs = () => {
        let tabList = [];
        tabList.push(<Tab label='Job Views' value='views' />);
        if (job)
            job.files
                .filter(f => f.category !== 'roadshow')
                .forEach((document, index) => {
                    tabList.push(
                        <Tab
                            label={document.displayName + ' Views'}
                            value={'' + index}
                        />
                    );
                });
        return tabList;
    };

    const downloadTabPanels = () =>
        job?.files
            .filter(f => f.category !== 'roadshow')
            .map((document, index) => {
                const notFoundLanguage = () => (
                    <TabPanel value={'' + index}>
                        <h3
                            style={{
                                fontFamily:
                                    '"Roboto", "Helvetica", "Arial", sans-serif'
                            }}>
                            This document has not been downloaded yet.
                        </h3>
                    </TabPanel>
                );

                if (!stats) {
                    return notFoundLanguage();
                }

                const documentWithDownloads = stats.downloads.find(
                    download => download.filename === document.fileName
                );

                //The below length check should never pass but being extra safe
                if (
                    !documentWithDownloads ||
                    documentWithDownloads.downloads.length === 0
                ) {
                    return notFoundLanguage();
                }

                return (
                    <TabPanel value={'' + index}>
                        <MaterialTable
                            title=''
                            columns={[
                                { title: 'Name', field: 'name' },
                                { title: 'Email', field: 'email' },
                                { title: 'Company', field: 'company' },
                                { title: 'Role', field: 'role' },
                                { title: 'Total Downloads', field: 'views' },
                                {
                                    title: 'Last Downloaded At',
                                    field: 'lastViewed'
                                }
                            ]}
                            data={documentWithDownloads.downloads.map(
                                download => {
                                    const marketRole = download.role
                                        ? constants.marketRole.find(
                                              e => e.name === download.role
                                          ).label
                                        : 'NA';

                                    return {
                                        name:
                                            download.firstName +
                                            ' ' +
                                            download.lastName,
                                        email: download.username,
                                        company: download.company,
                                        role: marketRole,
                                        views: download.totalViews,
                                        lastViewed: moment(
                                            download.lastViewed
                                        ).format('MM-DD-YYYY')
                                    };
                                }
                            )}
                            options={{
                                exportButton: true,
                                paging: true,
                                pageSize: 10,
                                emptyRowsWhenPaging: false,
                                pageSizeOptions: [10, 25, 100]
                            }}
                        />
                    </TabPanel>
                );
            });

    const viewColumns = [
        { title: 'Name', field: 'name' },
        { title: 'Email', field: 'email' },
        { title: 'Company', field: 'company' },
        { title: 'Role', field: 'role' },
        { title: 'Total Views', field: 'views' },
        { title: 'Last Viewed At (ET)', field: 'lastViewed' }
    ];
    const jobHasRoadshow = job?.files.some(f => f.category === 'roadshow');
    if (jobHasRoadshow) {
        viewColumns.push({
            title: 'Roadshow Furthest Slide Watched',
            field: 'furthestPointWatched'
        });
    }
    return (
        <Container maxWidth='lg'>
            <div className={classes.fab}>{backToView()}</div>
            <Box sx={{ width: '100%' }}>
                <h2
                    style={{
                        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif'
                    }}>
                    {job?.issuer}
                </h2>
                <TabContext value={value}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <TabList
                            onChange={handleChange}
                            aria-label='lab API tabs example'>
                            {tabs()}
                        </TabList>
                    </Box>
                    <TabPanel value='views'>
                        {stats?.views.length > 0 && (
                            <MaterialTable
                                title=''
                                columns={viewColumns}
                                data={stats?.views.map(row => {
                                    const marketRole = row.role
                                        ? constants.marketRole.find(
                                              e => e.name === row.role
                                          ).label
                                        : 'NA';

                                    const tableRow = {
                                        name:
                                            row.firstName + ' ' + row.lastName,
                                        email: row.username,
                                        company: row.company,
                                        role: marketRole,
                                        views: row.totalViews,
                                        lastViewed: moment(
                                            row.lastViewed
                                        ).format('MM-DD-YYYY hh:mm A')
                                    };
                                    if (jobHasRoadshow) {
                                        tableRow['furthestPointWatched'] =
                                            row.roadshowSlideProgress ?? '0';
                                    }
                                    return tableRow;
                                })}
                                options={{
                                    exportButton: true,
                                    paging: true,
                                    pageSize: 10,
                                    emptyRowsWhenPaging: false,
                                    pageSizeOptions: [10, 25, 100]
                                }}
                            />
                        )}
                        {stats?.views.length === 0 && (
                            <h3
                                style={{
                                    fontFamily:
                                        '"Roboto", "Helvetica", "Arial", sans-serif'
                                }}>
                                This job has not been viewed yet.
                            </h3>
                        )}
                    </TabPanel>
                    {downloadTabPanels()}
                </TabContext>
            </Box>
        </Container>
    );
}
