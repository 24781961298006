import React from 'react';
import './../assets/scss/App.scss';
import { HttpHelpers } from './HttpHelpers';
import '../components/types/AuthJwtProps';
// import {IAuthJwtProps} from "./types/AuthJwtProps";
import { makeStyles } from '@material-ui/core/styles';
import { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Modal } from '@material-ui/core';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import TextField from '@material-ui/core/TextField';

interface IProps {
    toggle: () => void;
    isOpen: boolean;
}

const useStyles = makeStyles(theme => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3)
    }
}));

const StartRegistrationModal = (props: IProps) => {
    const [email, setEmail] = useState('');
    const classes = useStyles();

    let history = useHistory();

    const onChange = e => {
        setEmail(e.target.value);
    };

    const onSubmit = e => {
        let thisInstance = this;
        HttpHelpers.postJsonUnauthenticated('/signup', { email: email })
            .then(result => {
                if (result.status == 200) {
                    result.json().then(j => {
                        history.push('/register');
                    });
                } else throw 'Got back status code : ' + result.status;
            })
            .catch(err => {
                console.log(err);
            });
    };

    return (
        <Modal
            aria-labelledby='transition-modal-title'
            aria-describedby='transition-modal-description'
            className={classes.modal}
            open={props.isOpen}
            onClose={props.toggle}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500
            }}>
            <Fade in={props.isOpen}>
                <div
                    style={{
                        transform: `translate(0%, -150%)`
                    }}
                    className={classes.paper}>
                    <h3 id='transition-modal-title'>Sign Up</h3>
                    <form noValidate autoComplete='off'>
                        <TextField
                            id='email'
                            label='Email'
                            variant='outlined'
                            onChange={onChange}
                        />
                        <TextField
                            id='password'
                            label='Password'
                            variant='outlined'
                            onChange={onChange}
                        />
                    </form>
                </div>
            </Fade>
        </Modal>
    );
};

export { StartRegistrationModal };
