/* eslint-disable no-use-before-define */
import * as React from 'react';
import { useEffect, useState } from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { HttpHelpers } from './HttpHelpers';
import { constants } from './constants';
import { makeStyles } from '@material-ui/core/styles';
import { useTheme } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    inputRoot: {
        color: 'green',
        paddingLeft: '40px'
    },
    outlinedRoot: {
        '&:hover $notchedOutline': {
            borderColor: 'red'
        },
        '&$focused $notchedOutline': {
            borderColor: 'green',
            borderWidth: 1
        }
    },
    notchedOutline: {},
    focused: {}
}));

export default function SearchAutocomplete(props) {
    const classes = useStyles();
    const [open, setOpen] = useState(false);

    const theme = useTheme();

    const [options, setOptions] = useState([]);
    const defaultLabelValue = 'Search for an offering...';
    const [labelValue, setLabelValue] = useState(
        props.inputValue !== '' ? '' : defaultLabelValue
    );

    useEffect(() => {
        if (props.clearSearch) {
            props.setInputValue('');
            setOptions([]);
            setLabelValue(defaultLabelValue);
        }
    }, [props.clearSearch]);

    return (
        <Autocomplete
            open={open}
            onOpen={() => {
                if (props.inputValue) {
                    setOpen(true);
                }
            }}
            onClose={() => setOpen(false)}
            value={props.inputValue}
            inputValue={props.inputValue}
            onInputChange={(e, value, reason) => {
                //console.log("onInputChange", e, value, reason);
                if (reason === 'reset') {
                    if (value === '') setLabelValue(defaultLabelValue);
                } else if (reason === 'input') {
                    setLabelValue('');
                    //TODO we should add throttling to this
                    let targetEndpoint = '/public/job/search/borrower';
                    if (props.accessToken !== '') {
                        targetEndpoint = '/job/search/borrower';
                    }
                    HttpHelpers.postJsonParseJson(
                        constants.urlPrefix + targetEndpoint,
                        { searchString: value },
                        props.accessToken,
                        props.refreshTokensCallback
                    )
                        .then(result => {
                            if (result.status === 200) {
                                const resultBody = result.body;
                                //console.log("Options : ", resultBody);
                                setOptions(resultBody.names);
                            } else if (result.status === 400) {
                                console.log('fail 400');
                            }
                        })
                        .catch(function (error) {
                            console.log('Request failed', error);
                        });
                } else if (reason === 'clear') {
                    props.setInputValue('');
                    setOptions([]);
                    setLabelValue(defaultLabelValue);
                } else throw 'Unknon reason for onInputChange ' + reason;

                props.setInputValue(value);
                if (!value) {
                    setOpen(false);
                }
            }}
            onFocus={() => {
                setLabelValue('');
            }}
            onBlur={() => {
                if (options.length === 0 && props.inputValue === '') {
                    setLabelValue(defaultLabelValue);
                }
            }}
            onChange={(e, value) => {
                props.onChange(value);
            }}
            onSelect={event => {
                //console.log("onSelect", event);
            }}
            options={options}
            //getOptionLabel={(option) => option.title}
            classes={{ inputRoot: classes.inputRoot }}
            renderInput={params => (
                <TextField
                    {...params}
                    label={labelValue}
                    variant='outlined'
                    InputLabelProps={{
                        shrink: false,
                        style: {
                            color: theme.palette.primary.dark,
                            paddingLeft: '40px'
                        }
                    }}
                    inputProps={{
                        ...params.inputProps,
                        style: {
                            paddingLeft: '40px',
                            color: theme.palette.primary.main,
                            width: '300px'
                        }
                    }}
                />
            )}
        />
    );
}
