import React, { useState, useRef, CSSProperties, useEffect } from 'react';
import { Grid, IconButton, Slider, Typography, Box } from '@material-ui/core';
import {
    PlayCircleFilled,
    PauseCircleFilled,
    Forward5,
    Replay5,
    Forward10,
    Replay10,
    Forward30,
    Replay30
} from '@material-ui/icons';

type JumpInterval = 5 | 10 | 30;

interface IAudioPlayerProps {
    source: string;
    audioStartSeconds?: number;
    jumpInterval?: JumpInterval;
    maxProgress: number;
    autoPlay: boolean;
    onPlayClicked?: () => void;
    onMaxProgressUpdated?: (maxProgress: number) => void;
    onAudioEnded?: () => void;
}

const styles: { [key: string]: CSSProperties } = {
    textAlignCenter: {
        textAlign: 'center'
    }
};

const AudioPlayer: React.FunctionComponent<IAudioPlayerProps> = (
    props: IAudioPlayerProps
): React.ReactElement => {
    //state
    const audioRef = useRef<HTMLAudioElement>();
    const [progress, setProgress] = useState(0);
    const [duration, setDuration] = useState(0);
    const [playing, setPlaying] = useState(props.autoPlay);
    //locals
    const jumpInterval = props.jumpInterval ?? 5;
    const forwardIcon =
        jumpInterval === 5 ? (
            <Forward5 fontSize='large' />
        ) : jumpInterval == 10 ? (
            <Forward10 fontSize='large' />
        ) : (
            <Forward30 fontSize='large' />
        );
    const replayIcon =
        jumpInterval === 5 ? (
            <Replay5 fontSize='large' />
        ) : jumpInterval === 10 ? (
            <Replay10 fontSize='large' />
        ) : (
            <Replay30 fontSize='large' />
        );

    //private helper functions
    const __toHHMMSS = (duration: number): string => {
        const h = Math.floor(duration / 3600);
        const m = Math.floor((duration % 3600) / 60);
        const s = Math.floor((duration % 3600) % 60);
        return h !== 0
            ? ('0' + h).slice(-2) + ':'
            : '' + ('0' + m).slice(-2) + ':' + ('0' + s).slice(-2);
    };

    const __audioTimeJump = (jumpAmount: number): void => {
        if (
            props.maxProgress !== 0 &&
            audioRef.current.currentTime + jumpAmount > props.maxProgress
        ) {
            audioRef.current.currentTime = props.maxProgress;
            setProgress(props.maxProgress);
        } else if (props.maxProgress === 0 && jumpAmount > 0) {
            return;
        } else if (audioRef.current.currentTime + jumpAmount > duration) {
            audioRef.current.currentTime = duration;
            setProgress(duration);
        } else if (audioRef.current.currentTime + jumpAmount < 0) {
            audioRef.current.currentTime = 0;
            setProgress(0);
        } else {
            audioRef.current.currentTime += jumpAmount;
            setProgress(previousProgress => previousProgress + jumpAmount);
        }
    };

    //progress tracking
    useEffect(() => {
        const progressInterval: NodeJS.Timeout = setInterval(() => {
            if (playing) {
                //set progress and maxProgress accordingly
                const _progress = progress + 1;
                setProgress(_progress);
                if (_progress > props.maxProgress) {
                    props.onMaxProgressUpdated?.(_progress);
                }

                //check for audio ended
                if (_progress > duration) {
                    setPlaying(props.autoPlay);
                    setProgress(0);
                    props.onAudioEnded?.();
                }
            }
        }, 1000);

        return () => clearInterval(progressInterval);
    }, [playing, progress, duration]);

    //componentDidUpdate equivalent
    const previousAutoPlayRef = useRef<boolean>(null);
    useEffect(() => {
        if (
            !props.autoPlay &&
            previousAutoPlayRef.current != null &&
            props.autoPlay != previousAutoPlayRef.current
        ) {
            setPlaying(false);
        }
        previousAutoPlayRef.current = props.autoPlay;
    });

    //handlers
    const handlePlayPauseClick: React.MouseEventHandler<HTMLButtonElement> = (
        _: React.MouseEvent<HTMLButtonElement>
    ): void => {
        if (playing) {
            audioRef.current.pause();
            setPlaying(false);
        } else {
            audioRef.current.play();
            setPlaying(true);
            props.onPlayClicked?.();
        }
    };

    const handleAudioLoadedMetadata: React.ReactEventHandler<
        HTMLAudioElement
    > = (): void => {
        const audioDuration = audioRef.current.duration;
        setDuration(audioDuration);
        //can't just check for audioStartSeconds being truthy here because it can be 0
        if (
            props.audioStartSeconds !== null &&
            typeof props.audioStartSeconds !== 'undefined'
        ) {
            //make sure we never try to start the video past the userLastWatchProgress
            if (props.audioStartSeconds > props.maxProgress) {
                props.audioStartSeconds = props.maxProgress;
            }
            //if audioStartSeconds > audioDuration | audioStartSeconds < 0 we have issues!
            if (props.audioStartSeconds > audioDuration) {
                console.error(
                    'IAudioPlayerProps::audioStartSeconds cannot be greater than audio duration!'
                );
                return;
            } else if (props.audioStartSeconds < 0) {
                console.error(
                    'IAudioPlayerProps::audioStartSeconds cannot be less than 0!'
                );
                return;
            }
            audioRef.current.currentTime = props.audioStartSeconds;
            setProgress(props.audioStartSeconds);
        }
    };

    const handleProgressBarDragging = (
        _: React.ChangeEvent,
        val: number
    ): void => {
        if (val > props.maxProgress) {
            setProgress(props.maxProgress);
        } else {
            setProgress(val);
        }
    };

    const handleProgressBarDragged = (
        _: React.ChangeEvent,
        val: number
    ): void => {
        if (val > props.maxProgress) {
            audioRef.current.currentTime = props.maxProgress;
        } else {
            audioRef.current.currentTime = val;
        }
    };

    const KeyDownHandlers = new Map<string, any>([
        ['ArrowLeft', () => __audioTimeJump(-jumpInterval)],
        ['ArrowRight', () => __audioTimeJump(jumpInterval)],
        [' ', () => handlePlayPauseClick(null)]
    ]);

    return (
        <div
            onKeyDown={e => {
                e.preventDefault();
                KeyDownHandlers.get(e.key)?.();
            }}>
            <Box border='1px solid lightgray' borderRadius={5}>
                <Grid container alignItems='center'>
                    <Grid item xs={4} style={styles.textAlignCenter}>
                        <IconButton
                            onClick={() => __audioTimeJump(-jumpInterval)}
                            title={`Back ${jumpInterval} seconds`}>
                            {replayIcon}
                        </IconButton>
                    </Grid>
                    <Grid item xs={4} style={styles.textAlignCenter}>
                        <IconButton
                            onClick={handlePlayPauseClick}
                            title={playing ? 'Pause' : 'Play'}>
                            {playing ? (
                                <PauseCircleFilled fontSize='large' />
                            ) : (
                                <PlayCircleFilled fontSize='large' />
                            )}
                        </IconButton>
                    </Grid>
                    <Grid item xs={4} style={styles.textAlignCenter}>
                        <IconButton
                            onClick={() => __audioTimeJump(jumpInterval)}
                            title={`Forward ${jumpInterval} seconds`}
                            disabled={progress >= props.maxProgress}>
                            {forwardIcon}
                        </IconButton>
                    </Grid>
                    <Grid item xs={3} style={styles.textAlignCenter}>
                        <Typography title='currentProgress'>
                            {__toHHMMSS(progress)}
                        </Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Slider
                            min={0}
                            max={duration}
                            step={1}
                            onChange={handleProgressBarDragging}
                            onChangeCommitted={handleProgressBarDragged}
                            value={progress}
                        />
                    </Grid>
                    <Grid item xs={3} style={styles.textAlignCenter}>
                        <Typography title='duration'>
                            {__toHHMMSS(duration)}
                        </Typography>
                    </Grid>
                </Grid>
                <audio
                    ref={audioRef}
                    src={props.source}
                    autoPlay={props.autoPlay}
                    onLoadedMetadata={handleAudioLoadedMetadata}
                />
            </Box>
        </div>
    );
};

export default AudioPlayer;
