import * as React from 'react';
import { useState } from 'react';
import './../assets/scss/App.scss';
import '../components/types/AuthJwtProps';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import { Dialog, TextField } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import clsx from 'clsx';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import FormHelperText from '@material-ui/core/FormHelperText';
import Grid from '@material-ui/core/Grid';
// @ts-ignore
import AdajeLogo from './../assets/img/adaje_logo.svg';

// @ts-ignore
import MqLogoNew from './../assets/img/MQ_Logo_RGB_COLOR.svg';
import { HttpHelpers } from './HttpHelpers';
import { constants } from './constants';
import { Alert, AlertTitle } from '@material-ui/lab';
import AddIcon from '@material-ui/icons/Add';
import Fab from '@material-ui/core/Fab';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
            flexWrap: 'wrap'
        },
        item: {
            marginTop: theme.spacing(2)
        },
        field: {
            width: '300px',
            overflowWrap: 'break-word'
        },
        margin: {
            margin: theme.spacing(1)
        }
    })
);
//https://stackoverflow.com/questions/59813926/usestate-to-update-multiple-values-in-react
interface IProps {}

const ForgotPassword = (props: IProps) => {
    const [allValues, setAllValues] = useState({
        confirmationCode: '',
        username: '',
        password: '',
        passwordConfirmation: ''
    });

    const [isPasswordResetEmailSent, setIsPasswordResetEmailSent] =
        useState(false);
    const [passwordResetState, setPasswordResetState] = useState('pending');
    const [validationError, setValidationError] = useState(null);
    const [userNotFoundModalOpen, setUserNotFoundModalOpen] = useState(false);

    let history = useHistory();

    const classes = useStyles();

    const onChange = e => {
        setAllValues({ ...allValues, [e.target.name]: e.target.value });
    };

    const onForgotPasswordSubmit = e => {
        e.preventDefault();
        console.log('submit');

        HttpHelpers.postJsonUnauthenticated(
            constants.urlPrefix + '/forgotPassword',
            { username: allValues.username }
        )
            .then(result => {
                if (result.status === 200) {
                    result.json().then(j => {
                        if (j.isSuccess) {
                            setIsPasswordResetEmailSent(true);
                        } else {
                            setUserNotFoundModalOpen(true);
                        }
                    });
                } else if (result.status === 400) {
                    console.log('fail 400');
                }
            })
            .catch(function (error) {
                console.log('Request failed', error);
            });
    };

    const onForgotPasswordConfirmationSubmit = e => {
        e.preventDefault();
        console.log('submit');

        var passwordPattern =
            '^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\\^$*.\\[\\]{}\\(\\)?\\-\\"!@#%&\\/,><\\\':;|_~`])\\S{8,99}$';
        var passwordRegex = new RegExp(passwordPattern);

        if (!passwordRegex.test(allValues.password)) {
            setValidationError(
                'Passwords must have at least 8 characters and contain uppercase letters, lowercase letters, numbers, and symbols.'
            );
            return false;
        } else if (allValues.password != allValues.passwordConfirmation) {
            setValidationError(
                'Password and password confirmation does not match'
            );
            return false;
        }

        HttpHelpers.postJsonUnauthenticated(
            constants.urlPrefix + '/forgotPassword/complete',
            {
                confirmationCode: allValues.confirmationCode,
                username: allValues.username,
                password: allValues.password
            }
        )
            .then(result => {
                if (result.status === 200) {
                    result.json().then(j => {
                        if (j.isSuccess) {
                            setPasswordResetState('success');
                        }
                    });
                } else if (result.status === 400) {
                    setPasswordResetState('failure');
                    setValidationError('Incorrect confirmation code');
                    console.log('fail 400');
                }
            })
            .catch(function (error) {
                console.log('Request failed', error);
            });
    };

    return (
        <Grid
            container
            spacing={0}
            direction='column'
            alignItems='center'
            justifyContent='center'
            style={{ minHeight: '80vh' }}>
            <RouterLink to={'/'}>
                <img src={MqLogoNew} alt={'Logo'} width={300} />
            </RouterLink>
            {/* Part 1, user enter's their email to have confirmation code sent to them */}
            {!isPasswordResetEmailSent && (
                <>
                    <Grid item>
                        <div className={classes.item}>
                            What is your username?
                        </div>
                        <FormControl
                            className={clsx(classes.item, classes.field)}
                            variant='outlined'>
                            <InputLabel htmlFor='outlined-username'>
                                Username
                            </InputLabel>
                            <OutlinedInput
                                name={'username'}
                                id='outlined-username'
                                value={allValues.username}
                                onChange={onChange}
                                labelWidth={70}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item>
                        <Button
                            variant='contained'
                            color='primary'
                            onClick={onForgotPasswordSubmit}
                            className={clsx(classes.item, classes.field)}>
                            Send Reset Password Email
                        </Button>
                    </Grid>
                </>
            )}
            {/* Part 2, user enters confirmation code and new password */}
            {isPasswordResetEmailSent && passwordResetState !== 'success' && (
                <>
                    <div className={classes.item}>
                        An email with the confirmation code has been sent.
                    </div>
                    <form
                        autoComplete='off'
                        onSubmit={onForgotPasswordConfirmationSubmit}>
                        <Grid item className={classes.item}>
                            <TextField
                                type='confirmationCode'
                                className={classes.field}
                                required
                                id='confirmationCode'
                                name='confirmationCode'
                                label='Confirmation Code'
                                variant='outlined'
                                onChange={onChange}
                            />
                        </Grid>
                        <Grid item className={classes.item}>
                            <TextField
                                type='password'
                                className={classes.field}
                                autoComplete='new-password'
                                required
                                id='password'
                                name='password'
                                label='New Password'
                                variant='outlined'
                                onChange={onChange}
                            />
                        </Grid>
                        <Grid item className={classes.item}>
                            <TextField
                                type='password'
                                className={classes.field}
                                autoComplete='new-password'
                                required
                                id='passwordConfirmation'
                                name='passwordConfirmation'
                                label='Confirm new password'
                                variant='outlined'
                                onChange={onChange}
                            />
                        </Grid>
                        <Grid item>
                            {validationError && (
                                <FormHelperText
                                    error={true}
                                    className={classes.field}>
                                    {validationError}
                                </FormHelperText>
                            )}
                        </Grid>
                        <Grid item className={classes.item}>
                            <Button
                                type='submit'
                                variant='contained'
                                color='primary'
                                className={clsx(classes.field)}>
                                Reset Password
                            </Button>
                        </Grid>
                    </form>
                </>
            )}
            {/* Part 3, On successfull password reset, show cta directing user to go back to signin page to signin */}
            {passwordResetState === 'success' && (
                <>
                    <Grid item className={classes.item}>
                        <div className={classes.item}>
                            You have successfully updated your password!
                        </div>
                    </Grid>
                    <Grid item className={classes.item}>
                        <Button
                            type='submit'
                            onClick={() =>
                                (window.location.pathname = '/signin')
                            }
                            variant='contained'
                            color='primary'
                            className={classes.field}>
                            Signin
                        </Button>
                    </Grid>
                </>
            )}
            <Dialog
                open={userNotFoundModalOpen}
                onClose={() => setUserNotFoundModalOpen(false)}>
                <Alert
                    severity='warning'
                    className={clsx(classes.margin)}
                    variant={'outlined'}>
                    <AlertTitle>
                        No{' '}
                        <a href='https://adajeinc.com'>
                            <img
                                src={AdajeLogo}
                                style={{
                                    verticalAlign: '-3px',
                                    marginLeft: '3px'
                                }}
                                alt={'Logo'}
                                width='68px'
                            />
                        </a>{' '}
                        account found for that email.
                        <br />
                        <br />
                        <Fab
                            aria-label='tracking'
                            onClick={() => history.push('/signup')}
                            size={'small'}
                            variant={'extended'}
                            color={'primary'}>
                            Sign Up Here
                        </Fab>
                    </AlertTitle>
                </Alert>
            </Dialog>
        </Grid>
    );
};

export { ForgotPassword };
