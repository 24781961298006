import * as React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import {
    Container,
    createStyles,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    Link as MLink,
    Modal,
    Paper,
    TextField,
    Theme,
    Tooltip
} from '@material-ui/core';
import { useHistory, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { constants } from './constants';
import { HttpHelpers } from './HttpHelpers';
import { Alert, AlertTitle } from '@material-ui/lab';
import { Link } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import LogoUploadCtrl from './LogoUploadCtrl';
import DeleteButton from './DeleteButton';
import Box from '@material-ui/core/Box';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Fab from '@material-ui/core/Fab';

interface Organization {
    name: string;
    organizationType: string;
    url: string;
    hasLogo: boolean;
}

interface IProps {
    accessToken: string;
    isAdmin: boolean;
    refreshTokensCallback: (history: History) => void;
    isEdit: boolean;
    signalAdajeWorking: (boolean, string) => void;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1,
            '& > *': {
                margin: theme.spacing(1)
            }
        },
        header: {
            color: theme.palette.primary.main,
            paddingBottom: '20px'
        },
        fab: {
            '& > *': {
                margin: theme.spacing(1)
            }
        },
        formControl: {
            margin: theme.spacing(3),
            width: 'calc(100% - ' + theme.spacing(3) * 2 + 'px)'
        },
        formHeader: {
            marginBottom: theme.spacing(2)
        }
    })
);

export default function OrganizationCreateUpdate(props: IProps) {
    const classes = useStyles();

    let history = useHistory();

    let { orgId } = useParams();

    const refreshTokens = () => {
        props.refreshTokensCallback(history);
    };

    const blankOrg: Organization = {
        name: '',
        organizationType: '',
        url: '',
        hasLogo: false
    };

    const initialSubmissionState = {
        status: 'pending',
        orgId: undefined,
        message: ''
    };

    const [org, setOrg] = React.useState(blankOrg);

    const [logoUrl, setLogoUrl] = React.useState<string | null>(null);
    const [logo, setLogo] = useState<File>();

    const [submissionState, setSubmissionState] = useState(
        initialSubmissionState
    );

    const [isDeleteConfirmationOpen, setIsDeleteConfirmationOpen] =
        React.useState(false);

    const maybeDelete = () => {
        if (props.isEdit)
            return (
                <Box m={3}>
                    <Fab
                        aria-label='back'
                        onClick={() => {
                            history.push('/org/table');
                        }}
                        size={'small'}
                        style={{ marginRight: '10px' }}>
                        <ArrowBackIcon />
                    </Fab>
                    <Tooltip title={'Delete Org'}>
                        <DeleteButton
                            onClick={() => {
                                setIsDeleteConfirmationOpen(true);
                            }}
                            label={undefined}
                        />
                    </Tooltip>
                </Box>
            );
    };

    const removeLogo = () => {
        setLogoUrl(null);
        setOrg({ ...org, hasLogo: false });
    };

    const addLogo = (url: string) => {
        setLogoUrl(url);
        setOrg({ ...org, hasLogo: true });
    };

    const updateOrg = e => {
        setOrg({ ...org, [e.target.name]: e.target.value });
    };

    const resetForm = () => {
        setOrg(blankOrg);
        setLogoUrl(null);
        setSubmissionState(initialSubmissionState);
    };

    const getOrgTypes = () => {
        let rv = [];
        for (let i = 0; i < constants.orgTypes.length; i++) {
            let orgType = constants.orgTypes[i];
            rv.push(
                <MenuItem key={orgType.name} value={orgType.name}>
                    {orgType.label}
                </MenuItem>
            );
        }
        return rv;
    };

    useEffect(() => {
        if (props.isEdit)
            HttpHelpers.postJsonParseJson(
                constants.urlPrefix + '/org/details',
                { orgId: orgId },
                props.accessToken,
                refreshTokens
            )
                .then(result => {
                    if (result.status === 200) {
                        const orgOption = result.body.orgOption;
                        if (orgOption) {
                            setOrg({
                                name: orgOption.name,
                                organizationType: orgOption.organizationType,
                                url: orgOption.url,
                                hasLogo: orgOption.hasLogo
                            });
                            console.log('response body: ', result.body);
                            setLogoUrl(result.body.logoOption);
                        } else {
                            alert('Organization not found');
                            history.push('/');
                        }
                    }
                })
                .catch(function (error) {
                    console.log('Request failed', error);
                });
    }, [props.isAdmin]);

    const deleteOrg = () => {
        HttpHelpers.deleteJson(
            constants.urlPrefix + '/org/' + orgId,
            true,
            props.accessToken,
            refreshTokens
        )
            .then(result => {
                if (result.status === 200) {
                    console.log('DELETED');
                } else if (result.status === 400) {
                    console.log('fail 400');
                }
            })
            .catch(function (error) {
                console.log('Request failed', error);
            });
    };

    const onSubmit = e => {
        e.preventDefault();

        const workingMessage = () => {
            if (props.isEdit) return 'Updating organization...';
            else return 'Creating organization...';
        };

        props.signalAdajeWorking(true, workingMessage());

        const url = () => {
            if (props.isEdit)
                return constants.urlPrefix + '/org/update/' + orgId;
            else return constants.urlPrefix + '/org/create';
        };

        let formData = new FormData();

        if (logoUrl && logo) {
            formData.append('logo', logo, 'logo.png');
        }

        formData.set('props', JSON.stringify(org));

        HttpHelpers.postFormData(
            url(),
            formData,
            props.accessToken,
            refreshTokens
        ).then(result => {
            if (result.status === 200) {
                const orgId = result.body.organization._id;
                setSubmissionState({
                    status: 'success',
                    orgId: orgId,
                    message: undefined
                });
            } else {
                setSubmissionState({
                    status: 'fail',
                    orgId: undefined,
                    message:
                        result.status === 400
                            ? result.body.message
                            : 'Something went wrong, please try again.'
                });
            }
            props.signalAdajeWorking(false, '');
        });
    };

    if (!props.isAdmin) return <div>Log in as admin to view.</div>;
    else if (submissionState.status === 'success')
        return (
            <Alert severity='success'>
                <AlertTitle>
                    {props.isEdit
                        ? 'Organization Updated'
                        : 'Organization Created'}
                </AlertTitle>
                <ul>
                    {!props.isEdit && (
                        <li key={'create'} style={{ marginBottom: '10px' }}>
                            <MLink
                                href='#'
                                onClick={e => {
                                    e.preventDefault();
                                    resetForm();
                                }}>
                                Create another organization
                            </MLink>
                        </li>
                    )}
                    <li key={'org'} style={{ marginBottom: '10px' }}>
                        <Link to='/org/table'>Return to organization list</Link>
                    </li>
                    <li key={'home'} style={{ marginBottom: '10px' }}>
                        <Link to='/'>Return home</Link>
                    </li>
                    <li key={'update'} style={{ marginBottom: '10px' }}>
                        <Link
                            to={'/org/update/' + submissionState.orgId}
                            onClick={() =>
                                setSubmissionState(initialSubmissionState)
                            }>
                            Edit organization
                        </Link>
                    </li>
                </ul>
            </Alert>
        );
    else
        return (
            <>
                <Dialog
                    open={
                        submissionState.status === 'fail' &&
                        !!submissionState.message
                    }>
                    <DialogTitle>Error</DialogTitle>
                    <DialogContent>
                        <Typography>{submissionState.message}</Typography>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={() => {
                                setSubmissionState(initialSubmissionState);
                            }}
                            color='primary'>
                            OK
                        </Button>
                    </DialogActions>
                </Dialog>
                <Container maxWidth='md'>
                    {maybeDelete()}
                    <Paper elevation={2}>
                        <form onSubmit={onSubmit}>
                            <FormControl className={classes.formControl}>
                                <Typography
                                    variant='h4'
                                    color='primary'
                                    align='center'
                                    className={classes.formHeader}>
                                    {props.isEdit
                                        ? 'Edit Organization'
                                        : 'Create Organization'}
                                </Typography>
                                <Grid container spacing={3}>
                                    <Grid item xs={6}>
                                        <TextField
                                            id='name'
                                            label='Org Name'
                                            variant='outlined'
                                            onChange={updateOrg}
                                            name='name'
                                            value={org.name}
                                            fullWidth
                                            required
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <FormControl
                                            variant='outlined'
                                            fullWidth>
                                            <InputLabel id='organizationType-label'>
                                                Org Type *
                                            </InputLabel>
                                            <Select
                                                labelId='organizationType-label'
                                                id='organizationType-select'
                                                name='organizationType'
                                                value={org.organizationType}
                                                onChange={updateOrg}
                                                label='Org Type'
                                                required>
                                                <MenuItem value=''>
                                                    <em>None</em>
                                                </MenuItem>
                                                {getOrgTypes()}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField
                                            id='url'
                                            label='Website URL'
                                            variant='outlined'
                                            onChange={updateOrg}
                                            name='url'
                                            value={org.url}
                                            fullWidth
                                        />
                                    </Grid>
                                </Grid>
                                <LogoUploadCtrl
                                    logoUrl={logoUrl}
                                    setLogoUrl={addLogo}
                                    setLogo={setLogo}
                                    removeLogo={removeLogo}
                                />
                            </FormControl>
                            <Button
                                variant='contained'
                                color='primary'
                                type='submit'
                                fullWidth>
                                Submit
                            </Button>
                        </form>
                    </Paper>
                    <Modal
                        open={isDeleteConfirmationOpen}
                        onClose={() => {
                            setIsDeleteConfirmationOpen(false);
                        }}>
                        <Box
                            sx={{
                                position: 'absolute',
                                left: '50%',
                                top: '50%',
                                // @ts-ignore
                                transform: 'translate(-50%, -50%)',
                                padding: '20px',
                                backgroundColor: 'white',
                                borderRadius: '10px',
                                outline: 'none'
                            }}>
                            <Typography
                                id='modal-modal-title'
                                variant='h6'
                                component='h2'>
                                Are you sure?
                            </Typography>
                            <div
                                style={{
                                    textAlign: 'center',
                                    marginTop: '10px'
                                }}>
                                <Button
                                    style={{ margin: '10px' }}
                                    variant='contained'
                                    color='primary'
                                    onClick={() => {
                                        deleteOrg();
                                        setIsDeleteConfirmationOpen(false);
                                        history.push('/org/table');
                                    }}>
                                    Yes
                                </Button>
                                <Button
                                    style={{ margin: '10px' }}
                                    variant='contained'
                                    color='primary'
                                    onClick={() => {
                                        setIsDeleteConfirmationOpen(false);
                                    }}>
                                    Cancel
                                </Button>
                            </div>
                        </Box>
                    </Modal>
                </Container>
            </>
        );
}
