import * as React from 'react';
import './../assets/scss/App.scss';
import '../components/types/AuthJwtProps';
import { useState, useEffect } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { TextField, Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import clsx from 'clsx';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormHelperText from '@material-ui/core/FormHelperText';
import IconButton from '@material-ui/core/IconButton';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import { Link as RouterLink } from 'react-router-dom';
// @ts-ignore
import MqLogoNew from './../assets/img/MQ_Logo_RGB_COLOR.svg';
import { HttpHelpers } from './HttpHelpers';
import { constants } from './constants';
import { Alert, AlertTitle } from '@material-ui/lab';
// @ts-ignore
import AdajeLogo from './../assets/img/adaje_logo.svg';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
            flexWrap: 'wrap'
        },
        margin: {
            margin: theme.spacing(1)
        },
        field: {
            width: '300px'
        },
        paper: {
            padding: theme.spacing(2),
            textAlign: 'left',
            color: theme.palette.text.primary,
            backgroundColor: theme.palette.grey['200']
        }
    })
);

//https://stackoverflow.com/questions/59813926/usestate-to-update-multiple-values-in-react
interface IProps {
    onSignin: (
        username: string,
        password: string,
        history: any
    ) => Promise<void>;
    customStyle: {};
    closeModal: (() => void) | null;
}

const Signin = (props: IProps) => {
    const [allValues, setAllValues] = useState({
        username: '',
        email: '',
        password: '',
        showPassword: false
    });

    let { code } = useParams();

    useEffect(() => {
        if (typeof code !== 'undefined') {
            HttpHelpers.postJsonUnauthenticated(
                constants.urlPrefix + '/register',
                { code: code }
            )
                .then(result => {
                    if (result.status === 200) {
                        result.json().then(j => {
                            if (j.isSuccess) {
                                setIsCodeVerified(true);
                            }
                        });
                    } else if (result.status === 400) {
                        console.log('fail 400');
                    }
                })
                .catch(function (error) {
                    console.log('Request failed', error);
                });
        }
    }, []);

    const [isCodeVerified, setIsCodeVerified] = useState(false);
    const [isFailedLogin, setIsFailedLogin] = useState(false);

    let history: History = useHistory();

    const classes = useStyles();

    const onChange = e => {
        setAllValues({ ...allValues, [e.target.name]: e.target.value });
    };

    const handleMouseDownPassword = (
        event: React.MouseEvent<HTMLButtonElement>
    ) => {
        event.preventDefault();
    };

    const handleKeyPressPassword = (event: React.KeyboardEvent) => {
        if (event.key == 'Enter') {
            onSubmit(event);
        }
    };

    const onSubmit = e => {
        e.preventDefault();
        console.log('submit');

        let error = false;

        props
            .onSignin(allValues.username, allValues.password, history)
            .then(() => setIsFailedLogin(false))
            .catch(err => {
                setIsFailedLogin(true);
                console.log('get error');
                console.log('is failed log in', isFailedLogin);
                console.log(err);
                error = true;
            })
            .finally(() => {
                if (!error && props.closeModal !== null) props.closeModal();
            });
    };

    const toggleShowPassword = () => {
        setAllValues({ ...allValues, showPassword: !allValues.showPassword });
    };

    return (
        <Grid
            container
            spacing={0}
            direction='column'
            alignItems='center'
            justifyContent='center'
            style={props.customStyle}>
            <RouterLink to={'/'}>
                <img src={MqLogoNew} alt={'Logo'} width={300} />
            </RouterLink>
            {isCodeVerified && (
                <div>
                    Thank you! Your account has been verified. Log in with your
                    email and password below.
                </div>
            )}
            <Grid item>
                <FormControl
                    className={clsx(classes.margin, classes.field)}
                    variant='outlined'>
                    <InputLabel htmlFor='outlined-username'>
                        Username
                    </InputLabel>
                    <OutlinedInput
                        name={'username'}
                        id='outlined-username'
                        value={allValues.username}
                        onChange={onChange}
                        labelWidth={70}
                    />
                </FormControl>
            </Grid>
            <Grid item>
                <FormControl
                    className={clsx(classes.margin, classes.field)}
                    variant='outlined'>
                    <InputLabel htmlFor='outlined-adornment-password'>
                        Password
                    </InputLabel>
                    <OutlinedInput
                        name={'password'}
                        id='outlined-adornment-password'
                        type={allValues.showPassword ? 'text' : 'password'}
                        value={allValues.password}
                        onChange={onChange}
                        onKeyPress={handleKeyPressPassword}
                        endAdornment={
                            <InputAdornment position='end'>
                                <IconButton
                                    aria-label='toggle password visibility'
                                    onClick={toggleShowPassword}
                                    onMouseDown={handleMouseDownPassword}
                                    edge='end'>
                                    {allValues.showPassword ? (
                                        <Visibility />
                                    ) : (
                                        <VisibilityOff />
                                    )}
                                </IconButton>
                            </InputAdornment>
                        }
                        labelWidth={70}
                    />
                </FormControl>
            </Grid>
            <Grid item>
                {isFailedLogin ? (
                    <FormHelperText error={true}>
                        Incorrect username or password.
                    </FormHelperText>
                ) : null}
                <Button
                    variant='contained'
                    color='primary'
                    onClick={onSubmit}
                    className={clsx(classes.margin, classes.field)}>
                    Sign In
                </Button>
            </Grid>
            <Grid item>
                <Alert
                    severity='info'
                    className={clsx(classes.margin)}
                    variant={'outlined'}>
                    <AlertTitle>
                        Please sign in with your{' '}
                        <a href='https://adajeinc.com'>
                            <img
                                src={AdajeLogo}
                                style={{
                                    verticalAlign: '-3px',
                                    marginLeft: '3px'
                                }}
                                alt={'Logo'}
                                width='68px'
                            />
                        </a>{' '}
                        credentials.
                        <br />
                        Don't have an account?{' '}
                        <RouterLink to={'/signup'}>Sign up</RouterLink>
                    </AlertTitle>
                </Alert>
            </Grid>
            <Grid item>
                <div className={classes.margin}>
                    <Typography>
                        Forgot Password?{' '}
                        <RouterLink
                            to={'/forgotPassword'}
                            onClick={props.closeModal}>
                            Reset Password
                        </RouterLink>
                    </Typography>
                </div>
            </Grid>
        </Grid>
    );
};

export { Signin };
