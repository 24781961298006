import * as React from 'react';
import { constants } from '../constants';
import * as moment from 'moment';

export function toTitleCase(str) {
    return str.replace(/\w\S*/g, function (txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
}

export var currencyFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',

    // These options are needed to round to whole numbers if that's what you want.
    //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    maximumFractionDigits: 0 // (causes 2500.99 to be printed as $2,501)
});

export function getJobType(jobTypeName: string) {
    return constants.jobTypes.find(e => {
        return e.name === jobTypeName;
    });
}

export function getUsState(stateName: string) {
    let replaceUnderlines = stateName.replace('_', ' ');
    return constants.usStates.find(e => {
        return e.name === replaceUnderlines;
    });
}

export function formatDate(date: string) {
    return moment(date).format('M/D/YYYY');
}
