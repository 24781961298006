import './../assets/scss/App.scss';
import '../components/types/AuthJwtProps';
import { createStyles, Paper, Theme } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Container from '@material-ui/core/Container';
import clsx from 'clsx';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1
        },
        paper: {
            padding: theme.spacing(2),
            textAlign: 'left',
            color: theme.palette.text.secondary
        },
        header: {
            color: theme.palette.primary.main
        },
        bottomItem: {
            minHeight: '70%'
        }
    })
);

const About = () => {
    const classes = useStyles();

    return (
        <Container maxWidth='lg'>
            <Grid container spacing={3} style={{ minHeight: '70vh' }}>
                <Grid item xs={12}>
                    <Paper className={classes.paper} elevation={0}>
                        <Typography variant='h3' className={classes.header}>
                            Who We Are
                        </Typography>
                        <br />
                        <Typography variant='body1'>
                            McElwee & Quinn LLC (M&Q) is an independent and
                            woman owned financial printing services company. We
                            specialize exclusively in the typesetting,
                            production, printing and electronic document posting
                            and distribution of Municipal Offering Statements.
                            M&Q was launched in 1991 by Mary McElwee as larger
                            printing firms began to abandon this niche market.
                            After three decades and thousands of posted/printed
                            municipal offerings, M&Q stands as one of the
                            oldest, most experienced financial printing firms in
                            the country. Producing Municipal Offering Statements
                            is the only printing we do. This distinction makes
                            us experts in, and keenly aware of, the needs within
                            the working group community: quick turn-around time,
                            document integrity and the 24/7 availability of a
                            friendly and communicative professional production
                            staff. We deliver all of this at a low economical
                            price. Please view our posted documents page and
                            contact us if we can be of further assistance.
                        </Typography>
                    </Paper>
                </Grid>
                <Grid item xs={12}>
                    <Paper
                        className={clsx(classes.paper, classes.bottomItem)}
                        elevation={0}>
                        <Typography variant='h4' className={classes.header}>
                            Quality Assurance
                        </Typography>
                        <br />
                        <Typography variant='body1'>
                            M&Q has built its business by providing
                            uncompromising customer service. We are eminently
                            proud of, and continue to fiercely protect our
                            stellar reputation for providing reliable and timely
                            printing at a competitive price. Every print project
                            is approached with a scrutinizing eye to detail. To
                            maintain document integrity, our seasoned staff of
                            production professionals adheres to a 10 point
                            quality check so that all our covers and books
                            consistently look their best. We make sure that
                            every page received is properly maintained and makes
                            sense with the overall flow of the document. Our
                            vast experience of working with thousands of
                            documents enables us to spot missing pieces of
                            relevant information that inadvertently weren't
                            included with the original document. Creating an
                            accurate and aesthetically pleasing offering
                            document is important to us - and something that the
                            working group can confidently send out to the
                            investment community.
                        </Typography>
                    </Paper>
                </Grid>
                <Grid item xs={12}>
                    <Paper
                        className={clsx(classes.paper, classes.bottomItem)}
                        elevation={0}>
                        <Typography variant='h4' className={classes.header}>
                            Friendly Customer Service
                        </Typography>
                        <br />
                        <Typography variant='body1'>
                            Our production staff is adept at tackling the most
                            challenging projects - whether working with scanned
                            financial documents, multiple changes to copy,
                            sending out hundreds of books for hard copy
                            distribution or sending out hundreds of emails for
                            electronic distribution, our clients know they can
                            rely on us to quickly and accurately pull together
                            all the disparate pieces into a secure marketable
                            document.
                        </Typography>
                    </Paper>
                </Grid>
                <Grid item xs={12}>
                    <Paper
                        className={clsx(classes.paper, classes.bottomItem)}
                        elevation={0}>
                        <Typography variant='h4' className={classes.header}>
                            Pricing
                        </Typography>
                        <br />
                        <Typography variant='body1'>
                            M&Q understands the competitive nature of the
                            industry and the importance of full disclosure when
                            bidding on printing projects.
                        </Typography>
                    </Paper>
                </Grid>
                <Grid item xs={12}>
                    <Paper
                        className={clsx(classes.paper, classes.bottomItem)}
                        elevation={0}>
                        <Typography variant='h4' className={classes.header}>
                            Technology
                        </Typography>
                        <br />
                        <Typography variant='body1'>
                            We know that our clients rely and depend on our
                            ability to electronically deliver a secure offering
                            document to working group members and investors. We
                            have undertaken all precautionary measures to ensure
                            that our web systems are never down. All of our
                            offering documents are highly encrypted when posted
                            to our website. Users are able to search for a
                            document by a multitude of factors.
                        </Typography>
                    </Paper>
                </Grid>
            </Grid>
        </Container>
    );
};

export default About;
