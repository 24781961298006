import * as React from 'react';
import { useState } from 'react';
import './../assets/scss/App.scss';
import '../components/types/AuthJwtProps';
import { Redirect, Route, Switch, useHistory } from 'react-router-dom';
import PaginatedTable from './PaginatedTable';
import About from './About';
import { StartRegistrationModal } from './StartRegistrationModal';
import NavBar from './NavBar';
import JobCreateEdit from './JobCreateEdit';
import JobView from './JobView';
import JobStats from './JobStats';
import OrganizationCreateUpdate from './OrganizationCreateUpdate';
import OrganizationTable from './OrganizationTable';
import { HttpHelpers } from './HttpHelpers';
import {
    alpha,
    createStyles,
    makeStyles,
    Theme
} from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        search: {
            position: 'relative',
            borderRadius: theme.shape.borderRadius,
            backgroundColor: alpha(theme.palette.common.white, 0.4),
            '&:hover': {
                backgroundColor: alpha(theme.palette.common.white, 0.4)
            },
            margin: theme.spacing(2),
            marginLeft: 0,
            width: 'auto',
            [theme.breakpoints.up('lg')]: {
                marginLeft: theme.spacing(3),
                width: 'auto'
            }
        },
        searchIcon: {
            padding: theme.spacing(0, 2),
            height: '100%',
            position: 'absolute',
            pointerEvents: 'none',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
        },
        jumbotron: {
            backgroundColor: alpha('#DAB337', 1),
            height: '100px'
        },
        grow: {
            flexGrow: 1
        },
        spaceAbove: {
            marginTop: 40
        }
    })
);

interface IProps {
    accessToken: string;
    username: string;
    isAdmin: boolean;
    onSignin: (
        username: string,
        password: string,
        history: any
    ) => Promise<void>;
    onSignOut: (history: any) => void;
    refreshTokensCallback: (history: History) => void;
    signalAdajeWorking: (boolean, string) => void;
}

const Main = (props: IProps) => {
    let history = useHistory();

    const classes = useStyles();

    const [signUpOpen, setSignUpOpen] = useState(false);

    const [searchValue, setSearchValue] = useState(null);
    const [clearSearch, setClearSearch] = useState(false);
    const [inputValue, setInputValue] = useState('');
    const [roadshowFileEntries, setRoadshowFileEntries] = useState([]);

    const toggleSignUp = () => {
        setSignUpOpen(!signUpOpen);
    };

    const isSignedIn =
        // true;
        props.accessToken != '';

    const username =
        // true;
        props.username;

    const isAdmin =
        // true;
        props.isAdmin;

    const clickVideoGo = e => {
        const url = HttpHelpers.buildUrl('/video');
        console.log(url);

        // HttpHelpers.getNonJson("/video", false, null, null)
        //     .then()
    };

    const getNav = () => {
        return (
            <NavBar
                isSignedIn={isSignedIn}
                isAdmin={isAdmin}
                accessToken={props.accessToken}
                refreshTokensCallback={props.refreshTokensCallback}
                onSignOut={props.onSignOut}
                toggleSignUp={toggleSignUp}
                setSearchValue={setSearchValue}
                clearSearch={clearSearch}
                setInputValue={setInputValue}
                inputValue={inputValue}
                setClearSearch={setClearSearch}
            />
        );
    };

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const resetToggles = () => {
        setStateName([]);
        setPrelimOrFinalValue(null);
        setNegOrCompValue(null);
    };

    const clearSearchAndResetPage = () => {
        setClearSearch(true);
        setSearchValue(null);
        setInputValue(null);
        setPage(0);
    };

    const [prelimOrFinalValue, setPrelimOrFinalValue] = useState(null);
    const [negOrCompValue, setNegOrCompValue] = useState(null);
    const [stateName, setStateName] = useState<string[]>([]);
    const [jobNumberToSearch, setJobNumberToSearch] = useState('');

    return (
        <div>
            {getNav()}
            <Switch>
                <Route path='/about'>
                    <About />
                </Route>
                <Route path='/createjob'>
                    <JobCreateEdit
                        isAdmin={isAdmin}
                        accessToken={props.accessToken}
                        refreshTokensCallback={props.refreshTokensCallback}
                        isEdit={false}
                        signalAdajeWorking={props.signalAdajeWorking}
                    />
                </Route>
                <Route path='/view/:jobId'>
                    <JobView
                        isAdmin={props.isAdmin}
                        accessToken={props.accessToken}
                        refreshTokensCallback={props.refreshTokensCallback}
                        signalAdajeWorking={props.signalAdajeWorking}
                    />
                </Route>
                <Route path='/stats/:jobId'>
                    <JobStats
                        isAdmin={props.isAdmin}
                        accessToken={props.accessToken}
                        refreshTokensCallback={props.refreshTokensCallback}
                    />
                </Route>
                <Route path='/edit/:jobId'>
                    <JobCreateEdit
                        isAdmin={props.isAdmin}
                        accessToken={props.accessToken}
                        refreshTokensCallback={props.refreshTokensCallback}
                        isEdit={true}
                        signalAdajeWorking={props.signalAdajeWorking}
                    />
                </Route>
                <Route path='/org/create'>
                    <OrganizationCreateUpdate
                        accessToken={props.accessToken}
                        isAdmin={props.isAdmin}
                        refreshTokensCallback={props.refreshTokensCallback}
                        isEdit={false}
                        signalAdajeWorking={props.signalAdajeWorking}
                    />
                </Route>
                <Route path='/org/update/:orgId'>
                    <OrganizationCreateUpdate
                        accessToken={props.accessToken}
                        isAdmin={props.isAdmin}
                        refreshTokensCallback={props.refreshTokensCallback}
                        isEdit={true}
                        signalAdajeWorking={props.signalAdajeWorking}
                    />
                </Route>
                <Route path='/org/table'>
                    <OrganizationTable
                        accessToken={props.accessToken}
                        isAdmin={props.isAdmin}
                        refreshTokensCallback={props.refreshTokensCallback}
                    />
                </Route>
                <Route exact path='/'>
                    <PaginatedTable
                        username={username}
                        isAdmin={isAdmin}
                        accessToken={props.accessToken}
                        refreshTokensCallback={props.refreshTokensCallback}
                        clearSearch={clearSearch}
                        setClearSearch={setClearSearch}
                        searchValue={searchValue}
                        setSearchValue={setSearchValue}
                        setInputValue={setInputValue}
                        inputValue={inputValue}
                        onSignin={props.onSignin}
                        page={page}
                        setPage={setPage}
                        rowsPerPage={rowsPerPage}
                        setRowsPerPage={setRowsPerPage}
                        resetToggles={resetToggles}
                        clearSearchAndResetPage={clearSearchAndResetPage}
                        prelimOrFinalValue={prelimOrFinalValue}
                        setPrelimOrFinalValue={setPrelimOrFinalValue}
                        negOrCompValue={negOrCompValue}
                        setNegOrCompValue={setNegOrCompValue}
                        stateName={stateName}
                        setStateName={setStateName}
                        jobNumberToSearch={jobNumberToSearch}
                        setJobNumberToSearch={setJobNumberToSearch}
                    />
                </Route>
                <Route path='/redirect/:random'>
                    <Redirect to='/' />
                </Route>
            </Switch>
            <StartRegistrationModal isOpen={signUpOpen} toggle={toggleSignUp} />
        </div>
    );
};

export { Main };
