import * as React from 'react';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';

interface IProps {
    value: string | null;
    onChange: (event: any, newStatus: any) => void;
}

export default function MyJobs(props: IProps) {
    return (
        <ToggleButtonGroup
            value={props.value}
            exclusive
            onChange={props.onChange}
            aria-label='my jobs'>
            <ToggleButton value='myjobs' aria-label='myjobs'>
                My Jobs
            </ToggleButton>
        </ToggleButtonGroup>
    );
}
