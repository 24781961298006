const setHost = () => {
    if (
        window.location.hostname === 'mcelweequinn.com' ||
        window.location.hostname === 'www.mcelweequinn.com'
    )
        return 'https://printer.adajeinc.com';
    if (
        window.location.hostname === 'printer.adaje.net' ||
        window.location.hostname === 'www.printer.adaje.net'
    )
        return 'https://printer-api.adaje.net';
    if (
        window.location.hostname === 'printer.staging.adaje.net' ||
        window.location.hostname === 'www.printer.staging.adaje.net'
    )
        return 'https://printer-api.staging.adaje.net';
    return 'http://localhost:8080';
};

const usStates = [
    { name: 'ALABAMA', abbreviation: 'AL' },
    { name: 'ALASKA', abbreviation: 'AK' },
    { name: 'AMERICAN SAMOA', abbreviation: 'AS' },
    { name: 'ARIZONA', abbreviation: 'AZ' },
    { name: 'ARKANSAS', abbreviation: 'AR' },
    { name: 'CALIFORNIA', abbreviation: 'CA' },
    { name: 'COLORADO', abbreviation: 'CO' },
    { name: 'CONNECTICUT', abbreviation: 'CT' },
    { name: 'DELAWARE', abbreviation: 'DE' },
    { name: 'DISTRICT OF COLUMBIA', abbreviation: 'DC' },
    { name: 'FEDERATED STATES OF MICRONESIA', abbreviation: 'FM' },
    { name: 'FLORIDA', abbreviation: 'FL' },
    { name: 'GEORGIA', abbreviation: 'GA' },
    { name: 'GUAM', abbreviation: 'GU' },
    { name: 'HAWAII', abbreviation: 'HI' },
    { name: 'IDAHO', abbreviation: 'ID' },
    { name: 'ILLINOIS', abbreviation: 'IL' },
    { name: 'INDIANA', abbreviation: 'IN' },
    { name: 'IOWA', abbreviation: 'IA' },
    { name: 'KANSAS', abbreviation: 'KS' },
    { name: 'KENTUCKY', abbreviation: 'KY' },
    { name: 'LOUISIANA', abbreviation: 'LA' },
    { name: 'MAINE', abbreviation: 'ME' },
    { name: 'MARSHALL ISLANDS', abbreviation: 'MH' },
    { name: 'MARYLAND', abbreviation: 'MD' },
    { name: 'MASSACHUSETTS', abbreviation: 'MA' },
    { name: 'MICHIGAN', abbreviation: 'MI' },
    { name: 'MINNESOTA', abbreviation: 'MN' },
    { name: 'MISSISSIPPI', abbreviation: 'MS' },
    { name: 'MISSOURI', abbreviation: 'MO' },
    { name: 'MONTANA', abbreviation: 'MT' },
    { name: 'NEBRASKA', abbreviation: 'NE' },
    { name: 'NEVADA', abbreviation: 'NV' },
    { name: 'NEW HAMPSHIRE', abbreviation: 'NH' },
    { name: 'NEW JERSEY', abbreviation: 'NJ' },
    { name: 'NEW MEXICO', abbreviation: 'NM' },
    { name: 'NEW YORK', abbreviation: 'NY' },
    { name: 'NORTH CAROLINA', abbreviation: 'NC' },
    { name: 'NORTH DAKOTA', abbreviation: 'ND' },
    { name: 'NORTHERN MARIANA ISLANDS', abbreviation: 'MP' },
    { name: 'OHIO', abbreviation: 'OH' },
    { name: 'OKLAHOMA', abbreviation: 'OK' },
    { name: 'OREGON', abbreviation: 'OR' },
    { name: 'PALAU', abbreviation: 'PW' },
    { name: 'PENNSYLVANIA', abbreviation: 'PA' },
    { name: 'PUERTO RICO', abbreviation: 'PR' },
    { name: 'RHODE ISLAND', abbreviation: 'RI' },
    { name: 'SOUTH CAROLINA', abbreviation: 'SC' },
    { name: 'SOUTH DAKOTA', abbreviation: 'SD' },
    { name: 'TENNESSEE', abbreviation: 'TN' },
    { name: 'TEXAS', abbreviation: 'TX' },
    { name: 'UTAH', abbreviation: 'UT' },
    { name: 'VERMONT', abbreviation: 'VT' },
    { name: 'VIRGIN ISLANDS', abbreviation: 'VI' },
    { name: 'VIRGINIA', abbreviation: 'VA' },
    { name: 'WASHINGTON', abbreviation: 'WA' },
    { name: 'WEST VIRGINIA', abbreviation: 'WV' },
    { name: 'WISCONSIN', abbreviation: 'WI' },
    { name: 'WYOMING', abbreviation: 'WY' }
];

const jobTypes = [
    { name: 'officialStatement', label: 'Official Statement' },
    { name: 'disclosureStatement', label: 'Disclosure Statement' },
    {
        name: 'offeringCircularMemorandum',
        label: 'Offering Circular/Memorandum'
    },
    {
        name: 'privatePlacementMemorandum',
        label: 'Private Placement Memorandum'
    },
    {
        name: 'remarketingCircularMemorandum',
        label: 'Remarketing Circular/Memorandum'
    },
    { name: 'remarketingSupplement', label: 'Remarketing Supplement' },
    { name: 'reofferingCircular', label: 'Reoffering Circular' },
    { name: 'supplement', label: 'Supplement' },
    { name: 'limitedOfferingMemorandum', label: 'Limited Offering Memorandum' }
];

const orgTypes = [
    { name: 'manager', label: 'Manager' },
    { name: 'advisor', label: 'Advisor' },
    { name: 'counsel', label: 'Counsel' },
    { name: 'borrower', label: 'Borrower' },
    { name: 'issuer', label: 'Issuer' }
];

const marketRole = [
    { name: 'analyst', label: 'Analyst / Research' },
    { name: 'attorney', label: 'Attorney' },
    { name: 'auditor', label: 'Auditor' },
    { name: 'banking', label: 'Banking' },
    { name: 'consultant', label: 'Consultant' },
    { name: 'financialAdvisor', label: 'Financial Advisor' },
    { name: 'dealer', label: 'Dealer / Underwriter' },
    { name: 'investorIndividual', label: 'Investor - Individual' },
    { name: 'investorInstitutional', label: 'Investor - Institutional' },
    { name: 'investorRetail', label: 'Investor - Retail' },
    { name: 'insuranceProvider', label: 'Insurance Provider' },
    { name: 'issuer', label: 'Issuer / Borrower' },
    { name: 'legalSecretary', label: 'Legal Secretary / Paralegal' },
    { name: 'media', label: 'Media' },
    { name: 'ratingAgency', label: 'Rating Agency' },
    { name: 'regulatory', label: 'Regulatory' },
    { name: 'salesInstitutional', label: 'Sales - Institutional' },
    { name: 'salesTaxable', label: 'Sales - Taxable' },
    { name: 'serviceProvider', label: 'Service Provider' },
    { name: 'trading', label: 'Trading' },
    { name: 'trustee', label: 'Trustee' },
    { name: 'other', label: 'Other' }
];

export const constants = {
    host: setHost(),
    urlPrefix: '/api',

    tokenRefreshTimeout: 1000 * 60 * 30, //Thirty minutes, token expires in 1hr
    logoutDueToInactiveTimeout: 1000 * 60 * 60 * 24, //A day

    usStates: usStates,
    jobTypes: jobTypes,
    orgTypes: orgTypes,
    marketRole: marketRole
};
