import * as React from 'react';
import './../assets/scss/App.scss';
import '../components/types/AuthJwtProps';
import { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import {
    TextField,
    Grid,
    FormControl,
    FormControlLabel,
    FormHelperText,
    MenuItem,
    Select,
    InputLabel,
    Checkbox,
    Typography
} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { HttpHelpers } from './HttpHelpers';
import { constants } from './constants';
// @ts-ignore
import MqLogoNew from './../assets/img/MQ_Logo_RGB_COLOR.svg';
import { Link as RouterLink } from 'react-router-dom';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { Alert, AlertTitle } from '@material-ui/lab';
// @ts-ignore
import AdajeLogo from './../assets/img/adaje_logo.svg';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import { Visibility, VisibilityOff } from '@material-ui/icons';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
            flexWrap: 'wrap'
        },
        item: {
            marginTop: theme.spacing(2)
        },
        field: {
            width: '300px',
            overflowWrap: 'break-word'
        },
        margin: {
            margin: theme.spacing(1)
        },
        adaje: {
            width: '270px',
            overflowWrap: 'break-word'
            // marginLeft: "0px"
        }
    })
);

interface SignupResponse {
    isSuccess: boolean;
    errors: {
        key: string;
        value: string;
    }[];
}

const SignUp = props => {
    const [allValues, setAllValues] = useState({
        username: '',
        password: '',
        confirmPassword: '',
        showPassword: false,
        firstName: '',
        lastName: '',
        role: '',
        phone: '',
        companyName: ''
    });

    const [isSignupComplete, setIsSignupComplete] = useState(false);
    const [isFailedSignup, setIsFailedSignup] = useState(false);
    const [signupErrors, setSignupErrors] = useState([]);
    const [passwordValidationError, setPasswordValidationError] =
        useState(null);
    const [emailValidationError, setEmailValidationError] = useState(null);
    const [confirmPasswordError, setConfirmPasswordError] = useState(null);
    const [showPostSubmitError, setShowPostSubmitError] = useState(null);

    const errorExists =
        passwordValidationError || emailValidationError || confirmPasswordError;

    const classes = useStyles();

    let history: History = useHistory();

    const onChange = e => {
        setAllValues({ ...allValues, [e.target.name]: e.target.value });

        if (e.target.name === 'password' && isValidPassword(e.target.value))
            setPasswordValidationError(false);

        if (e.target.name === 'username' && isValidEmail(e.target.value))
            setEmailValidationError(false);

        if (
            e.target.name == 'confirmPassword' &&
            allValues.password === e.target.value
        )
            setConfirmPasswordError(false);
    };

    const isValidPassword = (password: string): boolean => {
        let passwordPattern =
            '^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\\^$*.\\[\\]{}\\(\\)?\\-\\"!@#%&\\/,><\\\':;|_~`])\\S{8,99}$';
        let passwordRegex = new RegExp(passwordPattern);
        return passwordRegex.test(password);
    };

    const isValidEmail = (email: string): boolean => {
        let emailPattern = '.*@.*\\.[A-Za-z]';
        let emailRegex = new RegExp(emailPattern);
        return emailRegex.test(email);
    };

    const passwordRequirementsMessage = () => {
        return 'Password must have at least 8 characters and contain an uppercase letter, lowercase letter, number, and symbol.';
    };

    const handleMouseDownPassword = (
        event: React.MouseEvent<HTMLButtonElement>
    ) => {
        event.preventDefault();
    };

    const onSubmit = e => {
        e.preventDefault();
        console.log('submit');
        setIsFailedSignup(false);
        setShowPostSubmitError(false);

        if (errorExists) {
            setShowPostSubmitError(true);
            return false;
        }

        let data = {
            username: allValues.username,
            password: allValues.password,
            firstName: allValues.firstName,
            lastName: allValues.lastName,
            role: allValues.role,
            phone: allValues.phone,
            companyName: allValues.companyName
        };

        HttpHelpers.postJsonUnauthenticated(
            constants.urlPrefix + '/signup',
            data
        )
            .then(result => {
                if (result.status == 200) {
                    result.json().then((j: SignupResponse) => {
                        setIsSignupComplete(true);
                        setSignupErrors(j.errors);
                        console.log('signup success : ', j);
                    });
                    //history.push('/')
                } else {
                    setIsFailedSignup(true);
                    throw 'Got back status code : ' + result.status;
                }
            })
            .catch(err => {
                setIsFailedSignup(true);
                console.log(err);
            });
    };

    return (
        <Grid
            container
            spacing={0}
            direction='column'
            alignItems='center'
            justifyContent='center'
            style={{ minHeight: '80vh' }}>
            <RouterLink to={'/'}>
                <img src={MqLogoNew} alt={'Logo'} width={300} />
            </RouterLink>

            {!isSignupComplete && (
                <form autoComplete='off' onSubmit={onSubmit}>
                    <Grid item className={classes.item}>
                        <Alert
                            severity='info'
                            className={clsx(classes.adaje)}
                            variant={'outlined'}>
                            <AlertTitle>
                                Already have an{' '}
                                <a href='https://adajeinc.com'>
                                    <img
                                        src={AdajeLogo}
                                        style={{
                                            verticalAlign: '-3px',
                                            marginLeft: '3px'
                                        }}
                                        alt={'Logo'}
                                        width='68px'
                                    />
                                </a>{' '}
                                account?{' '}
                                <RouterLink to={'/signin'}>
                                    Sign in here.
                                </RouterLink>
                            </AlertTitle>
                        </Alert>
                    </Grid>
                    <Grid item className={classes.item}>
                        <TextField
                            type='email'
                            className={classes.field}
                            required
                            id='username'
                            name='username'
                            label='Email'
                            variant='outlined'
                            onChange={onChange}
                            error={emailValidationError}
                            onBlur={() =>
                                setEmailValidationError(
                                    !isValidEmail(allValues.username)
                                )
                            }
                        />
                    </Grid>
                    <Grid item className={classes.item}>
                        <FormControl
                            className={clsx(classes.field)}
                            variant='outlined'>
                            <InputLabel
                                error={passwordValidationError}
                                htmlFor='outlined-adornment-password'>
                                Password *
                            </InputLabel>
                            <OutlinedInput
                                name={'password'}
                                id='outlined-adornment-password'
                                type={
                                    allValues.showPassword ? 'text' : 'password'
                                }
                                value={allValues.password}
                                onChange={onChange}
                                endAdornment={
                                    <InputAdornment position='end'>
                                        <IconButton
                                            aria-label='toggle password visibility'
                                            onClick={() =>
                                                setAllValues({
                                                    ...allValues,
                                                    showPassword:
                                                        !allValues.showPassword
                                                })
                                            }
                                            onMouseDown={
                                                handleMouseDownPassword
                                            }
                                            edge='end'>
                                            {allValues.showPassword ? (
                                                <Visibility />
                                            ) : (
                                                <VisibilityOff />
                                            )}
                                        </IconButton>
                                    </InputAdornment>
                                }
                                labelWidth={85}
                                error={passwordValidationError}
                                onBlur={() =>
                                    setPasswordValidationError(
                                        !isValidPassword(allValues.password)
                                    )
                                }
                            />
                            <FormHelperText error={passwordValidationError}>
                                {passwordRequirementsMessage()}
                            </FormHelperText>
                        </FormControl>
                    </Grid>
                    <Grid item className={classes.item}>
                        <TextField
                            type='password'
                            className={classes.field}
                            required
                            id='confirmPassword'
                            name='confirmPassword'
                            label='Confirm Password'
                            variant='outlined'
                            onChange={onChange}
                            error={confirmPasswordError}
                            helperText={
                                confirmPasswordError
                                    ? 'Passwords do not match.'
                                    : ''
                            }
                            onBlur={() =>
                                setConfirmPasswordError(
                                    allValues.password !==
                                        allValues.confirmPassword
                                )
                            }
                        />
                    </Grid>
                    <Grid item className={classes.item}>
                        <TextField
                            className={classes.field}
                            required
                            id='firstName'
                            name='firstName'
                            label='First Name'
                            variant='outlined'
                            onChange={onChange}
                        />
                    </Grid>
                    <Grid item className={classes.item}>
                        <TextField
                            className={classes.field}
                            required
                            id='lastName'
                            name='lastName'
                            label='Last Name'
                            variant='outlined'
                            onChange={onChange}
                        />
                    </Grid>
                    <Grid item className={classes.item}>
                        <TextField
                            className={classes.field}
                            required
                            id='companyName'
                            name='companyName'
                            label='Company'
                            variant='outlined'
                            onChange={onChange}
                        />
                    </Grid>
                    <Grid item className={classes.item}>
                        <FormControl
                            variant='outlined'
                            className={classes.field}>
                            <InputLabel id='role-label'>Role *</InputLabel>
                            <Select
                                required
                                labelId='role-label'
                                id='role-select'
                                name='role'
                                value={allValues.role}
                                onChange={onChange}
                                label='Role*'>
                                <MenuItem value=''>
                                    <em>Select Role...</em>
                                </MenuItem>
                                {constants.marketRole.map(role => (
                                    <MenuItem value={role.name}>
                                        {role.label}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item className={classes.item}>
                        <TextField
                            type='tel'
                            className={classes.field}
                            id='phone'
                            name='phone'
                            label='Phone'
                            variant='outlined'
                            onChange={onChange}
                        />
                    </Grid>
                    <Grid item>
                        <FormHelperText
                            className={classes.field}
                            margin='dense'
                            error={false}>
                            You (the “User”) are granted access to
                            https://www.mcelweequinn.com (the “Site”) “as is”,
                            without warranty of any kind, express or implied,
                            including but not limited to the warranties of
                            merchantability, fitness for a particular purpose
                            and noninfringement. User will use the Site solely
                            for User’s own, internal business purposes. User
                            agrees to use the Site in compliance with all
                            applicable local, state, federal and international
                            laws, regulations, and conventions. User’s usage of
                            the Site, including any information provided by the
                            User, may be used for purposes of delivering the
                            services offered through the Site or by Adaje Inc.
                        </FormHelperText>
                        <FormControlLabel
                            value='terms'
                            control={<Checkbox required />}
                            label={
                                <Typography variant='body2'>
                                    I agree to the terms and conditions.
                                </Typography>
                            }
                            labelPlacement='end'
                        />
                    </Grid>
                    <Grid item>
                        {showPostSubmitError ? (
                            <FormHelperText
                                className={classes.field}
                                error={true}>
                                <Typography variant={'h6'}>
                                    Please fix errors above.
                                </Typography>
                            </FormHelperText>
                        ) : null}
                        {isFailedSignup ? (
                            <FormHelperText
                                className={classes.field}
                                error={true}>
                                Something went wrong, please try again later.
                            </FormHelperText>
                        ) : null}
                        <Button
                            type='submit'
                            variant='contained'
                            color={'primary'}
                            className={clsx(classes.field, classes.item)}>
                            Sign Up
                        </Button>
                    </Grid>
                </form>
            )}

            {isSignupComplete &&
                signupErrors.find(e => e.value === 'userExists') && (
                    <Grid item className={classes.item}>
                        <Alert
                            severity='info'
                            className={clsx(classes.adaje)}
                            variant={'outlined'}>
                            <AlertTitle>
                                You've already got an{' '}
                                <a href='https://adajeinc.com'>
                                    <img
                                        src={AdajeLogo}
                                        style={{
                                            verticalAlign: '-3px',
                                            marginLeft: '3px'
                                        }}
                                        alt={'Logo'}
                                        width='68px'
                                    />
                                </a>{' '}
                                account!{' '}
                                <RouterLink to={'/signin'}>
                                    Sign in here.
                                </RouterLink>
                            </AlertTitle>
                        </Alert>
                    </Grid>
                )}

            {isSignupComplete && signupErrors.length === 0 && (
                <Grid item>
                    <div>
                        A verification link has been sent to your email account:{' '}
                        {allValues.username}
                    </div>
                    <div>
                        Please click on the link to verify your email and
                        complete the registration process.
                    </div>
                </Grid>
            )}
        </Grid>
    );
};

export { SignUp };
